import React, { useState, useMemo, useEffect } from "react";
import { Box, Button, Chip, Typography, Grid } from "@mui/material";
import AddAction from "../../../Components/AddAction/AddAction";
import ReactTable from "../../../Components/CustomTable/CustomTable";
import AxiosInstance from "../../../Config/AxiosInstance";
import CustomModal from "../../../Components/CustomModel/CustomModel";
import groovyWalkAnimation from "../../../utils/driverAnimation.json";
import Swal from "sweetalert2";
import Lottie from "lottie-react";
import { useSocket } from "../../../Config/Socket/SocketProvider";
import useWindowDimension from "../../../Components/hooks/useWindowDimension";
import moment from "moment";
import CustomButton from "../../../Components/CustomButton/CustomButton";
const RestaurauntReadyfordelivery = () => {
  const YANDIWEB = JSON.parse(localStorage.getItem("YandiWeb")) ?? null;
  const socket = useSocket();
  const [page, setPage] = useState(0);
  const dimensions = useWindowDimension();
  const pageSize = 30;
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [driverTimeout, setDriverTimeout] = useState(null);
  console.log('driverTimeout', driverTimeout);
  const [readyForDriverList, setReadyForDriverList] = useState([]);
  const [readyForDriverModel, setReadyForDriverModel] = useState(false);
  const [readyForDriverDetails, setReadyForDriverDetails] = useState(false);
  const [vehicleTypeModel, setVehicleTypeModel] = useState(false);
  const [vehicleList, setVehicleList] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [orderDetailsStatus, setOrderDetailsStatus] = useState({
    orderId: null,
    status: null,
    orderStatus: null,
  });
  const columns = useMemo(
    () => [
      {
        Header: (props) => (
          <Box
            sx={{
              textAlign: "center",
              maxWidth: "100px",
              width: "100px",
            }}
          >
            Order ID
          </Box>
        ),
        accessor: "id",
        Cell: (row) => {
          return (
            <Box
              sx={{
                textAlign: "center",
                maxWidth: "100px",
                width: "100px",
              }}
            >
              {row.row.index + 1}
            </Box>
          );
        },
      },
      {
        Header: (props) => (
          <Box
            sx={{
              textAlign: "center",
              maxWidth: "200px",
              width: "200px",
            }}
          >
            Name
          </Box>
        ),
        accessor: "name", // Update this line
        Cell: (row) => {
          return (
            <Box
              sx={{
                textAlign: "center",
                maxWidth: "200px",
                width: "200px",
              }}
            >
              {row?.row?.original?.user_details?.full_name || '__'}
            </Box>
          );
        },
      },
      {
        Header: (props) => (
          <Box
            sx={{
              textAlign: "center",
              maxWidth: "200px",
              width: "200px",
            }}
          >
            Phone Number
          </Box>
        ),
        accessor: "phone_number",
        Cell: (row) => {
          const { phno_cc, phno } = row?.row?.original?.user_details || {};
          const phoneNumber = `${phno_cc} ${phno}`;
          return (
            <Box
              sx={{
                textAlign: "center",
                maxWidth: "200px",
                width: "200px",
              }}
            >
              {phoneNumber}
            </Box>
          );
        },
      },
      {
        Header: (props) => (
          <Box
            sx={{
              textAlign: "center",
              maxWidth: "200px",
              width: "200px",
            }}
          >
            Item
          </Box>
        ),
        accessor: `item`,
        Cell: (row) => {
          return (
            <Box
              sx={{
                textAlign: "center",
                maxWidth: "200px",
                width: "200px",
              }}
            >
              {row?.row?.original?.item_count}
            </Box>
          );
        },
      },
      {
        Header: (props) => (
          <Box
            sx={{
              textAlign: "center",
              maxWidth: "200px",
              width: "200px",
            }}
          >
            Status
          </Box>
        ),
        accessor: `status`,
        Cell: (row) => {
          return (
            <Box
              sx={{
                textAlign: "center",
                maxWidth: "200px",
                width: "200px",
              }}
            >
              {row?.row?.original?.order_status === 2 && (
                <Chip
                  label="Ready"
                  sx={{ backgroundColor: "#10A2E0", color: "#ffffff" }}
                />
              )}
              {row?.row?.original?.order_status === 3 && (
                <Chip
                  label="Finding..."
                  sx={{ backgroundColor: "#FFA500", color: "#ffffff" }}
                />
              )}
              {row?.row?.original?.order_status === 4 && (
                <Chip
                  label="assign to driver"
                  sx={{ backgroundColor: "#10A2E0", color: "#ffffff" }}
                />
              )}
            </Box>
          );
        },
      },
      {
        Header: (props) => (
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            Action
          </Box>
        ),
        accessor: `action`,
        Cell: (row) => {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gridGap: "10px",
              }}
            >
              <Button
                sx={{
                  width: "120px",
                  background: "#10A2E0",
                  border: "1px solid #10A2E0",
                  color: "#ffffff",
                  fontSize: "16px",
                  fontWeight: "600",
                  textTransform: "capitalize",
                  fontFamily: "Poppins",
                  height: "45px",
                  padding: "12px 20px",
                  "&:hover": {
                    background: "#10A2E0",
                    border: "1px solid #10A2E0",
                  },
                }}
                onClick={() => handleViewOrderPrepare(row?.row?.original?.id)}
              >
                View
              </Button>
              {row?.row?.original?.order_status === 3 ? (
                <Typography
                  sx={{
                    width: "120px",
                    background: "rgba(20, 35, 40, 1)",
                    border: "1px solid rgba(20, 35, 40, 1)",
                    color: "#ffffff",
                    fontSize: "16px",
                    fontWeight: "600",
                    textTransform: "capitalize",
                    fontFamily: "Poppins",
                    height: "45px",
                    padding: "12px 20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "8px",
                    "&:hover": {
                      background: "rgba(20, 35, 40, 1)",
                      border: "1px solid rgba(20, 35, 40, 1)",
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: "80px",
                      height: "80px",
                    }}
                  >
                    <Lottie animationData={groovyWalkAnimation} loop={true} />
                  </Box>
                </Typography>
              ) : (
                <Button
                  sx={{
                    width: "120px",
                    background: "rgba(20, 35, 40, 1)",
                    border: "1px solid rgba(20, 35, 40, 1)",
                    color: "#ffffff",
                    fontSize: "16px",
                    fontWeight: "600",
                    textTransform: "capitalize",
                    fontFamily: "Poppins",
                    height: "45px",
                    padding: "12px 20px",
                    "&:hover": {
                      background: "rgba(20, 35, 40, 1)",
                      border: "1px solid rgba(20, 35, 40, 1)",
                    },
                  }}
                  onClick={() =>
                    handleSelectVehicleType(row?.row?.original?.id, 3, 3)
                  }
                >
                  Find Driver
                </Button>
              )}
            </Box>
          );
        },
      },
    ],
    []
  );
  const handleNextPage = () => {
    setCount((prevCount) => prevCount + pageSize);
    setPage((prevCount) => prevCount + 1);
  };
  const handlePrevPage = () => {
    if (count >= pageSize) {
      setCount((prevCount) => prevCount - pageSize);
      setPage((prevCount) => prevCount - 1);
    }
  };
  const getAllOrder = async () => {
    try {
      setIsLoading(true);
      const response = await AxiosInstance.get(
        `/admin/restaurant/get-all-order?restaurant_id=${YANDIWEB?.id}`
      );
      if (response && response.data.s === 1) {
        const filteredData = response.data.r.filter((item) => {
          return item.order_status === 2 || item?.order_status === 3;
        });
        setReadyForDriverList(filteredData);
      }
    } catch (error) {
      console.error("Error fetching Order list:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getAllOrder();
  }, []);
  useEffect(() => {
    if (socket) {
      const GET_ACCEPT_DRIVER = async (data) => {
        if (data.type === 2 && data.status === 4) {
          await getAllOrder();
          Swal.fire({
            position: "center",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
            title: "Success",
            text: data.status_message,
          });
          clearTimeout(driverTimeout);
        }
      };
      socket.on("get-data", GET_ACCEPT_DRIVER);

      return () => {
        socket.off("get-data", GET_ACCEPT_DRIVER);
      };
    }
  }, [socket, getAllOrder, driverTimeout]);
  const handleViewOrderPrepare = async (id) => {
    setReadyForDriverModel(true);
    try {
      const response = await AxiosInstance.get(
        `admin/restaurant/get-order-details?order_id=${id}`
      );
      if (response && response?.data?.s === Number(1)) {
        setReadyForDriverDetails(response?.data?.r);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleUpdateOrderStatus = (orderId, orderStatus) => {
    let dataListener = async (data) => {
      if (data.type === 8 && data.status === 12 && data?.ride_id > 0) {
        await getAllOrder();
        setTimeout(() => {
          socket.emit("send-data", {
            type: 1,
            start_lat: YANDIWEB?.lat,
            start_lang: YANDIWEB?.lang,
            ride_id: data?.ride_id,
          });
        }, 3000);
        // Swal.fire({
        //   position: "center",
        //   icon: "success",
        //   showConfirmButton: false,
        //   timer: 2000,
        //   title: "Success",
        //   text: data.status_message,
        // });
        clearTimeout(driverTimeout);
        socket.off("get-data", dataListener);
      }
    };
    socket.on("get-data", dataListener);
    socket.emit("send-data", {
      type: 8,
      order_id: orderId,
      order_status: orderStatus,
    });
  };
  useEffect(() => {
    getChooseVehicleList();
  }, []);
  const getChooseVehicleList = async () => {
    try {
      const response = await AxiosInstance.get(`/service/vehicle/get-all`);
      if (response && response?.data?.s) {
        const responseData = response?.data?.r;
        setVehicleList(responseData);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleSelectVehicleType = (orderId, status, orderStatus) => {
    setVehicleTypeModel(true);
    setOrderDetailsStatus({ orderId, status, orderStatus });
  };
  const handleSelectVehicleOnSubmit = async () => {
    setIsLoading(true);
    const { orderId, status, orderStatus } = orderDetailsStatus;
    if (selectedVehicle) {
        try {
            const response = await AxiosInstance.get(`/admin/restaurant/get-order-details?order_id=${orderId}`);
            if (response && response.data?.s) {
                const order_Id = response.data.r.id;
                const dataListener = async (data) => {
                    if (data.type === 8 && data.status === 12 && data.ride_id > 0) {
                        const formData = new FormData();
                        formData.append("vehicle_id", selectedVehicle);
                        formData.append("ride_id", data.ride_id);
                        const response = await AxiosInstance.post("/ride/ride-update", formData);
                        if (response && response.data?.s) {
                            await getAllOrder();
                            setSelectedVehicle(null);
                            setTimeout(() => {
                                socket.emit("send-data", {
                                    type: 1,
                                    start_lat: YANDIWEB.lat,
                                    start_lang: YANDIWEB.lang,
                                    ride_id: data.ride_id,
                                });
                            }, 3000);
                            Swal.fire({
                                position: "center",
                                icon: "success",
                                showConfirmButton: false,
                                timer: 2000,
                                title: "Success",
                                text: data.status_message,
                            });
                            clearTimeout(driverTimeout);
                            socket.off("get-data", dataListener);
                        }
                    }
                };
                socket.on("get-data", dataListener);
                socket.emit("send-data", {
                    type: 8,
                    order_id: order_Id,
                    order_status: orderStatus,
                });
                const timeout = setTimeout(() => {
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: "Timeout",
                        text: "No driver accepted the ride within 5 minutes. Please try again.",
                        showConfirmButton: true,
                    });
                    socket.off("get-data", dataListener);
                    handleUpdateOrderStatus(order_Id, 2);
                    getAllOrder();
                }, 500000);

                setDriverTimeout(timeout);
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setIsLoading(false);
            setVehicleTypeModel(false);
        }
    }
};

  return (
    <Box sx={{ padding: "0px 15px" }}>
      <AddAction title={"Ready for Delivery"} />
      <Box
        sx={{
          padding: "30px 0px",
        }}
      >
        <ReactTable
          columns={columns}
          data={readyForDriverList}
          isLoading={isLoading}
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
          pageSize={pageSize}
          disableNext={readyForDriverList.length < pageSize}
          disablePrev={count === 0}
          pagination={true}
          maxHeight={dimensions.height - 190}
        />
      </Box>
      <CustomModal
        show={readyForDriverModel}
        onCloseClick={() => setReadyForDriverModel(false)}
      >
        <Box
          sx={{
            padding: "20px",
          }}
        >
          <Box
            sx={{
              margin: "0px 0px 25px 0px",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#000000",
              }}
            >
              Order #{readyForDriverDetails?.id}
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#797b7e",
                margin: "5px 0px 0px 0px",
                fontFamily: "Poppins",
              }}
            >
              {moment(readyForDriverDetails?.created_at).format(
                "DD MMM YYYY, hh:mm A"
              )}
            </Typography>
          </Box>
          <Grid container spacing={3}>
            {readyForDriverDetails &&
              readyForDriverDetails?.order_details?.map((elem, index) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    key={index}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "1px solid #e3e3e3",
                        paddingBottom: "18px",
                      }}
                    >
                      <Box sx={{}}>
                        <img
                          src={`https://api.yandi.app${elem?.menu?.cover_img}`}
                          alt="order"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "15px",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: "calc(100% - 100px)",
                          marginLeft: "20px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "end",
                        }}
                      >
                        <Box className="orderDesc">
                          <Typography
                            sx={{
                              fontSize: "16px",
                              color: "#000",
                              fontWeight: "400",
                              fontFamily: "Poppins",
                            }}
                          >
                            {elem?.menu?.item_name}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color: "#797B7E",
                              fontWeight: "400",
                              fontFamily: "Poppins",
                            }}
                          >
                            {`GNF${elem?.menu?.price}`}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color: "#797B7E",
                              fontWeight: "400",
                              fontFamily: "Poppins",
                            }}
                          >
                            Qty: {elem?.qty}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      </CustomModal>
      <CustomModal
        show={vehicleTypeModel}
        onCloseClick={() => setVehicleTypeModel(false)}
      >
        <Typography
          sx={{
            color: "rgba(0, 0, 0, 1)",
            fontWeight: "600",
            fontSize: "18px",
            fontFamily: "Poppins",
            marginBottom: "24px",
          }}
        >
          Select Cab Booking Type
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            flexDirection: "column",
            gridGap: "20px",
          }}
        >
          {vehicleList &&
            vehicleList?.map((elem, index) => {
              if (elem.vehicle_type.toLowerCase() === "car") {
                return null;
              }
              return (
                <Button
                  key={index}
                  type="button"
                  sx={{
                    fontSize: "18px",
                    fontWeight: "400",
                    backgroundColor:
                      selectedVehicle === elem.id ? "#00e67630" : "#F4F4F4",
                    color: "#000",
                    width: "100%",
                    height: "75px",
                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: "rgba(212, 251, 232, 1)",
                      color: "#000",
                    },
                  }}
                  onClick={() => setSelectedVehicle(elem?.id)}
                >
                  {elem?.vehicle_type}
                </Button>
              );
            })}
          <Box sx={{ textAlign: "center", width: "200px", margin: "0 auto" }}>
            <CustomButton
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                fontWeight: "700",
                fontSize: "18px",
                height: "56px",
                margin: "0 auto",
                textTransform: "unset",
                fontFamily: "Inter",
                background: "#00E676",
                "&:hover": {
                  background: "#00E676",
                },
              }}
              onClick={() => handleSelectVehicleOnSubmit()}
              btnTitle="Save"
              isLoading={isLoading}
            />
          </Box>
        </Box>
      </CustomModal>
    </Box>
  );
};

export default RestaurauntReadyfordelivery;
