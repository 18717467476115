import { Box, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AddAction from "../../../../Components/AddAction/AddAction";
import CustomSearchBox from "../../../../Components/CustomSearch/CustomSearch";
import ReactTable from "../../../../Components/CustomTable/CustomTable";
import AxiosInstance from "../../../../Config/AxiosInstance";
import useWindowDimension from "../../../../Components/hooks/useWindowDimension";
import useDebounce from "../../../../Components/useDebounce/useDebounce";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Swal from "sweetalert2";
const DriversManagement = () => {
  const navigate = useNavigate();
  const dimensions = useWindowDimension();
  const [driverSearch, setDriverSearch] = useState("");
  const [driversList, setDriversList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const debouncedSearch = useDebounce(driverSearch, 500);
  const [page, setPage] = useState(0);
  const pageSize = 30;
  const [count, setCount] = useState(0);
  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "rowid",
        Cell: (row) => row?.row?.original?.rowid,
      },
      {
        Header: "Driver Name",
        accessor: (d) => d.full_name,
      },
      {
        Header: "Email",
        accessor: (d) => d.email,
      },
      {
        Header: "Mobile Number",
        accessor: (d) => `${d?.phno_cc} ${d?.phno}`,
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gridGap: "10px",
            }}
          >
            <IconButton
              sx={{
                backgroundcColor: "#ffffff",
                boxsShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                border: "1px solid #ddd",
              }}
              onClick={() =>
                navigate("/partner/drivers/view", {
                  state: {
                    driversId: row?.original?.id,
                  },
                })
              }
            >
              <RemoveRedEyeIcon
                sx={{
                  fontSize: "24px",
                  color: "#0098F6",
                }}
              />
            </IconButton>
            <>
              {row?.original?.is_approve === 0 && (
                <>
                  <IconButton sx={{
                    backgroundcColor: "#ffffff",
                    boxsShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                    border: "1px solid #ddd",
                  }} onClick={() => handleApproveRejectStatus(row?.original?.id, 1)}>
                    <CheckIcon sx={{
                      fontSize: "24px",
                      color: "green",
                    }} />
                  </IconButton>
                  <IconButton sx={{
                    backgroundcColor: "#ffffff",
                    boxsShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                    border: "1px solid #ddd",
                  }} onClick={() => handleApproveRejectStatus(row?.original?.id, -1)}>
                    <CloseIcon sx={{
                      fontSize: "24px",
                      color: "red",
                    }} />
                  </IconButton></>
              )}
            </>

            {/* <IconButton
              sx={{
                backgroundcColor: "#ffffff",
                boxsShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                border: "1px solid #ddd",
              }}
              onClick={() =>
                navigate("/partner/drivers/edit", {
                  state: {
                    driversId: row?.original?.id,
                    type: "edit",
                  },
                })
              }
            >
              <EditIcon
                sx={{
                  fontSize: "24px",
                  color: "#000000",
                }}
              />
            </IconButton> */}
            {/* <IconButton
              sx={{
                backgroundcColor: "#ffffff",
                boxsShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                border: "1px solid #ddd",
              }}
            >
              <PersonIcon
                sx={{
                  fontSize: "24px",
                  color: "#000000",
                }}
              />
            </IconButton> */}
          </Box>
        ),
      },
    ],
    []
  );
  useEffect(() => {
    getDriversList();
  }, [debouncedSearch]);
  const getDriversList = async () => {
    try {
      const storedItem = window.localStorage.getItem("YandiWeb");
      const sessionId = storedItem ? JSON.parse(storedItem).id : undefined;
      if (sessionId) {
        const response = await AxiosInstance.get("/user/get-all", {
          params: {
            role: 4,
            count: count,
            add_by: sessionId,
            search: debouncedSearch,
          },
        });
        if (response && response.data.s === 1) {
          const Rowdata = response?.data?.r?.map((data, i) => ({
            rowid: i + 1 + page * 30,
            ...data,
          }));
          setDriversList(Rowdata);
        }
      } else {
        console.error("Session ID is undefined");
      }
    } catch (error) {
      console.error("Error fetching drivers list:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleNextPage = () => {
    setCount((prevCount) => prevCount + pageSize);
    setPage((prevCount) => prevCount + 1);
  };

  const handlePrevPage = () => {
    if (count >= pageSize) {
      setCount((prevCount) => prevCount - pageSize);
      setPage((prevCount) => prevCount - 1);
    }
  };
  const handleApproveRejectStatus = async (id, type) => {
    Swal.fire({
      title: "Are you sure?",
      text: type === 1 ? "You want to approve this driver?" : "You want to reject this driver?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await AxiosInstance.post("/user/update", {
            user_id: id,
            is_approve: type,
          });

          if (response && response.data.s === 1) {
            getDriversList();
            Swal.fire({
              position: "center",
              icon: "success",
              title: type === 1 ? "Driver Approved!" : "Driver Rejected!",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "There was an error processing your request. Please try again later.",
            });
          }
        } catch (error) {
          console.error("Error updating status:", error);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong. Please try again later.",
          });
        }
      }
    });
  };

  return (
    <Box sx={{ padding: "0px 15px" }}>
      <AddAction
        title={"Driver Management"}
        btnTitle={"Add Driver"}
        onClick={() => navigate("/partner/drivers/add")}
        showButton={false}
      />
      <Box sx={{ paddingY: "20px" }}>
        <CustomSearchBox
          placeholder="Search Driver"
          value={driverSearch}
          onChange={(e) => setDriverSearch(e.target.value)}
          onClear={() => setDriverSearch("")}
        />
      </Box>
      <Box>
        <ReactTable
          columns={columns}
          data={driversList}
          isLoading={isLoading}
          maxHeight={dimensions.height - 230}
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
          pageSize={pageSize}
          disableNext={driversList.length < pageSize}
          disablePrev={count === 0}
          pagination={true}
        />
      </Box>
    </Box>
  );
};

export default DriversManagement;
