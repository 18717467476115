import { Fragment, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as Yup from "yup";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { Box, Typography, Grid, IconButton, Button } from "@mui/material";
import TopHeader from "../../../../Components/TopHeader/TopHeader";
import UPLOAD_GALLERY_ICON from "../../../../Assets/Images/admin/gallery.png";
import AxiosInstance from "../../../../Config/AxiosInstance";
import PhoneInput from "react-phone-input-2";
import { sendPasswordResetEmail } from "firebase/auth";
import CustomButton from "../../../../Components/CustomButton/CustomButton";
import Constant from "../../../../utils/constant";
import { AUTH_LOGIN } from '../../../../Config/Firebase/Firebase';
const PartnerAddManagement = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const uploadCoverButtonRef = useRef(null);
  const uploadIdCardCoverButtonRef = useRef(null);
  const handleTabKeyPress = (event) => {
    if (event.keyCode === 13) {
      const fileInput = document.getElementById("cover-upload");
      fileInput.click();
    }
  };
  const handleIdCardTabKeyPress = (event) => {
    if (event.keyCode === 13) {
      const fileInput = document.getElementById("id-card");
      fileInput.click();
    }
  };
  const [typeCheck, setTypeCheck] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [busy, setBusy] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoadingPassword, setIsLoadingPassword] = useState(false);
  const [coverImageUploadPreview, setCoverImageUploadPreview] = useState(null);
  const [coverImageUploadFile, setCoverImageUploadFile] = useState(null);
  const [idCard, setIdCard] = useState(null);
  const [idCardName, setIdCardName] = useState(null);
  const [partnerEditId, setPartnerEditId] = useState(null);
  const [partnerEditDetails, setPartnerEditDetails] = useState({});
  console.log('partnerEditDetails', partnerEditDetails);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  //EditMode
  useEffect(() => {
    if (location?.state?.partnerId) {
      const partnerType = location?.state?.type;
      const partnerId = location?.state?.partnerId;
      setPartnerEditId(partnerId);
      setTypeCheck(partnerType);
      getPartnerDetails(partnerId);
    }
  }, []);
  const getPartnerDetails = async (partnerId) => {
    setBusy(true);
    try {
      const response = await AxiosInstance.get(
        `/user/get-by-id?user_id=${partnerId}`
      );
      if (response && response?.data?.r) {
        setPartnerEditDetails(response?.data?.r);
        const partnerDetails = response?.data?.r;
        formik.setValues({
          email: partnerDetails?.email ? partnerDetails.email : "",
          partnerName: partnerDetails?.full_name
            ? partnerDetails.full_name
            : "",
          phoneNumber: partnerDetails?.phno ? partnerDetails.phno : "",
          taxationNumber:
            partnerDetails?.user_document?.length > 0
              ? JSON.parse(partnerDetails.user_document[0]?.doc_reference)
                ?.taxationNumber
              : "",
          phno_cc: partnerDetails?.phno_cc.startsWith("+")
            ? partnerDetails.phno_cc.slice(1)
            : partnerDetails.phno_cc,
          address:
            partnerDetails?.user_address?.length > 0
              ? partnerDetails.user_address[0]?.address
              : "",
        });
        if (partnerDetails.profile_img) {
          setCoverImageUploadPreview(
            `https://api.yandi.app${partnerDetails?.profile_img}`
          );
          setCoverImageUploadFile(partnerDetails?.profile_img);
        } else {
          setCoverImageUploadPreview(null);
          setCoverImageUploadFile(null);
        }
        if (partnerDetails?.user_document?.length > 0) {
          const documentPath = partnerDetails?.user_document[0]?.document;
          const documentName = documentPath?.split("/").pop();
          setIdCardName(documentName);
        } else {
          setIdCard(null);
          setIdCardName(null);
        }
      }
    } catch (error) {
      // Handle error if needed
    } finally {
      setIsLoading(false);
      setBusy(false);
    }
  };
  //AddMode
  const handleCoverImageOnChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size <= 5 * 1024 * 1024) {
        const imageUrl = URL.createObjectURL(file);
        setCoverImageUploadFile(file);
        setCoverImageUploadPreview(imageUrl);
      } else {
        toast.error(`File size exceeds the limit of 5MB`);
      }
    }
  };
  const handleIdCardOnChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size <= 5 * 1024 * 1024) {
        setIdCard(file);
        setIdCardName(file.name);
        formik.setFieldValue("idCard", file);
      } else {
        toast.error(`File size exceeds the limit of 5MB`);
      }
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      partnerName: "",
      email: "",
      password: "",
      phoneNumber: "",
      taxationNumber: "",
      phno_cc: "",
      address: "",
      idCard: null,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(Constant.REGEX.EMAIL, "Please Enter Valid Email Address")
        .required("Email is required"),
      ...(typeCheck !== "edit" && {
        password: Yup.string()
          .min(6, "Password must be at least 6 characters")
          .max(20, "Password must be at most 20 characters")
          .required("Password is required"),
      }),
      partnerName: Yup.string()
        .matches(/^[a-zA-Z]+(?:\s+[a-zA-Z]+)*$/, "Alphabet character only")
        .required("Partner name is required"),
      phoneNumber: Yup.string().required("Phone number is required"),
      taxationNumber: Yup.string()
        .matches(/^[0-9]+$/, "Taxation number must contain only digits")
        .required("Taxation number is required"),
      address: Yup.string().required("Address is required"),
      idCard:
        typeCheck === "edit"
          ? Yup.mixed()
          : Yup.mixed().required("ID card is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      if (isLoading) {
        return;
      } else {
        if (typeCheck === "edit") {
          const formData1 = new FormData();
          formData1.append("user_id", partnerEditId);
          formData1.append("full_name", values.partnerName);
          formData1.append("phno", values.phoneNumber);
          formData1.append("phno_cc", `+${values.phno_cc}`);
          formData1.append("email", values.email);
          if (coverImageUploadFile) {
            formData1.append("profile_img", coverImageUploadFile);
          }
          try {
            const res = await AxiosInstance.post("/user/update", formData1);
            if (res?.data?.s === 1) {
              const formData2 = new FormData();
              formData2.append("id", partnerEditDetails?.user_address[0]?.id);
              formData2.append("type", 1);
              formData2.append("address", values.address);
              const formData3 = new FormData();
              formData3.append("id", partnerEditDetails?.user_document[0]?.id);
              formData3.append(
                "doc_reference",
                JSON.stringify({ taxationNumber: values.taxationNumber })
              );
              if (idCard) {
                formData3.append("document", idCard);
              }
              const [res1, res2] = await Promise.all([
                AxiosInstance.post("/user/address/update", formData2).catch(
                  (error) => console.error("Error in API call 2:", error)
                ),
                AxiosInstance.post("/user/document/update", formData3).catch(
                  (error) => console.error("Error in API call 3:", error)
                ),
              ]);

              if (res1?.data?.s === 1) {
              } else {
                toast.error(res1?.data?.m);
              }

              if (res2?.data?.s === 1) {
              } else {
                toast.error(res2?.data?.m);
              }
              toast.success(res?.data?.m);
              navigate("/admin/partner");
              resetForm();
              setIdCard(null);
              setIdCardName(null);
              setCoverImageUploadFile(null);
              setCoverImageUploadPreview(null);
            }
          } catch (error) {
            console.log("error", error);
          } finally {
            setIsLoading(false);
            setBusy(false);
          }
        } else {
          const formData1 = new FormData();
          formData1.append("full_name", values.partnerName);
          formData1.append("phno", values.phoneNumber);
          formData1.append("phno_cc", `+${values.phno_cc}`);
          formData1.append("email", values.email);
          formData1.append("password", values.password);
          if (coverImageUploadFile) {
            formData1.append("profile_img", coverImageUploadFile);
          }
          try {
            const res = await AxiosInstance.post(
              "/admin/add-partner",
              formData1
            );
            if (res?.data?.s === 1) {
              const formData2 = new FormData();
              formData2.append("user_id", res?.data?.r?.id);
              formData2.append("type", 1);
              formData2.append("address", values.address);
              const formData3 = new FormData();
              formData3.append("user_id", res?.data?.r?.id);
              formData3.append(
                "doc_reference",
                JSON.stringify({ taxationNumber: values.taxationNumber })
              );
              if (idCard) {
                formData3.append("document", idCard);
              }
              const [res1, res2] = await Promise.all([
                AxiosInstance.post("/user/address/add", formData2).catch(
                  (error) => console.error("Error in API call 2:", error)
                ),
                AxiosInstance.post("/user/document/add", formData3).catch(
                  (error) => console.error("Error in API call 3:", error)
                ),
              ]);

              if (res1?.data?.s === 1) {
              } else {
                toast.error(res1?.data?.m);
              }

              if (res2?.data?.s === 1) {
              } else {
                toast.error(res2?.data?.m);
              }
              toast.success(res?.data?.m);
              navigate("/admin/partner");
              resetForm();
              setIdCard(null);
              setIdCardName(null);
              setCoverImageUploadFile(null);
              setCoverImageUploadPreview(null);
            }
          } catch (error) {
            console.log("error", error);
          } finally {
            setIsLoading(false);
            setBusy(false);
          }
        }
      }
    },
  });
  const handleResetPasswordPartner = async () => {
    setIsLoadingPassword(true);
    const formData = new FormData();
    formData.append("user_id", partnerEditId);
    formData.append("email", partnerEditDetails?.email);
    try {
      const response = await AxiosInstance.post("/admin/reset-password", formData);
      if (response?.data?.s === 1) {
        toast.success(response?.data?.m);
        navigate('/admin/partner');
      } else {
        toast.error(response?.data?.m);
      }
      setIsLoadingPassword(false);
    } catch (error) {
      console.log("error", error);
      setIsLoadingPassword(false);
    }
  }
  return (
    <Fragment>
      <TopHeader
        title={typeCheck === "edit" ? "Update Partner" : "Add Partner"}
        onClick={() => navigate(-1)}
        type="button"
        btnTitle="Submit"
        onSubmit={formik.handleSubmit}
        loading={isLoading}
        showButton={true}
      />
      <Box
        sx={{
          padding: "80px 15px 40px 15px",
          maxWidth: { xs: "100%", md: "90%", lg: "80%", xl: "70%" },
          margin: "0 auto",
          width: "100%",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: "16px",
            fontWeight: "600",
            color: "#000000",
            textAlign: "left",
            padding: "14px 0px 14px 0px",
            fontFamily: "Poppins",
          }}
        >
          Basic information
        </Typography>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {isLoading || busy ? (
                <Skeleton height={150} />
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    height: "150px",
                    overflow: "hidden",
                    background: "#F4F4F4",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {!coverImageUploadPreview && (
                    <label htmlFor="cover-upload">
                      <Button
                        component="span"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          outline: "none",
                          border: "none",
                          backgroundColor: "transparent",
                          textTransform: "capitalize",
                        }}
                        ref={uploadCoverButtonRef}
                        onKeyDown={handleTabKeyPress}
                        type="button"
                      >
                        <img
                          src={UPLOAD_GALLERY_ICON}
                          alt="Upload Icon"
                          effect="blur"
                          width={"42px"}
                        />
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#000000",
                            margin: "8px 0px !important",
                          }}
                        >
                          Upload Profile
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#9C9C9C",
                          }}
                        >
                          {`(JPG,JPEG,PNG)`}
                        </Typography>
                        <input
                          accept="image/*"
                          style={{ display: "none" }}
                          id="cover-upload"
                          type="file"
                          onChange={handleCoverImageOnChange}
                        />
                      </Button>
                    </label>
                  )}
                  {coverImageUploadPreview && (
                    <Box>
                      <img
                        src={coverImageUploadPreview}
                        alt="Uploaded Image"
                        effect="blur"
                        height={"150px"}
                        width={"150px"}
                      />
                      <label htmlFor="cover-upload">
                        <IconButton
                          component="span"
                          sx={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "16px",
                            backgroundColor: "#ffffff",
                            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                            transition: "0.5s",
                            position: "absolute",
                            right: "5px",
                            top: "5px",
                            "&:hover": {
                              backgroundColor: "#ffffff",
                              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                            },
                          }}
                        >
                          <EditIcon
                            sx={{
                              color: "#46ADF2",
                              fontSize: "26px",
                            }}
                          />
                        </IconButton>
                        <input
                          accept="image/*"
                          style={{ display: "none" }}
                          id="cover-upload"
                          type="file"
                          onChange={handleCoverImageOnChange}
                        />
                        <IconButton
                          component="span"
                          sx={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "16px",
                            backgroundColor: "#ffffff",
                            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                            transition: "0.5s",
                            position: "absolute",
                            right: "50px",
                            top: "5px",
                            "&:hover": {
                              backgroundColor: "#ffffff",
                              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                            },
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setCoverImageUploadPreview(null);
                            setCoverImageUploadFile(null);
                          }}
                        >
                          <CloseIcon
                            sx={{
                              color: "#46ADF2",
                              fontSize: "26px",
                            }}
                          />
                        </IconButton>
                      </label>
                    </Box>
                  )}
                </Box>
              )}
            </Grid>
            <Grid item xs={12}>
              {isLoading || busy ? (
                <Skeleton height={48} />
              ) : (
                <input
                  type="text"
                  name="partnerName"
                  placeholder="Full Name"
                  value={formik.values.partnerName}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.trimStart() !== value) {
                      e.target.value = value.trimStart();
                    }
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  style={{
                    padding: "20px",
                    borderRadius: "8px",
                    outline: "none",
                    border: `1px solid ${formik.touched.partnerName && formik.errors.partnerName
                      ? "red"
                      : "#DDDDDD"
                      }`,
                    width: "100%",
                  }}
                  onKeyUp={formik.handleBlur}
                />
              )}
              {formik.touched.partnerName && formik.errors.partnerName ? (
                <Typography fontSize={14} fontWeight={500} mt={1} color={"red"}>
                  {formik.errors.partnerName}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              {isLoading || busy ? (
                <Skeleton height={48} />
              ) : (
                <input
                  type="text"
                  name="email"
                  placeholder="Email Address"
                  value={formik.values.email}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.trimStart() !== value) {
                      e.target.value = value.trimStart();
                    }
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  style={{
                    padding: "20px",
                    borderRadius: "8px",
                    outline: "none",
                    border: `1px solid ${formik.touched.email && formik.errors.email
                      ? "red"
                      : "#DDDDDD"
                      }`,
                    width: "100%",
                  }}
                  autoComplete="new-Email"
                  disabled={typeCheck === "edit" ? true : false}
                  onKeyUp={formik.handleBlur}
                />
              )}
              {formik.touched.email && formik.errors.email ? (
                <Typography fontSize={14} fontWeight={500} mt={1} color={"red"}>
                  {formik.errors.email}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              {isLoading || busy ? (
                <Skeleton height={48} />
              ) : (
                <>
                  <PhoneInput
                    name="phoneNumber "
                    country={"in"}
                    placeholder="Mobile Number"
                    value={formik.values.phno_cc + formik.values.phoneNumber}
                    onChange={(value, country) => {
                      const countryCodeLength = country.dialCode.length;
                      const phno_cc = value.slice(0, countryCodeLength);
                      const phno = value.slice(countryCodeLength);
                      formik.setFieldValue("phoneNumber", phno);
                      formik.setFieldValue("phno_cc", phno_cc);
                    }}
                    inputClass="inputClass"
                    style={{
                      border: `1px solid ${formik.touched.phoneNumber && formik.errors.phoneNumber
                        ? "red"
                        : "#DDDDDD"
                        }`,
                      borderRadius: "8px",
                    }}
                  />
                  {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                    <Typography
                      fontSize={14}
                      fontWeight={500}
                      mt={1}
                      color={"red"}
                    >
                      {formik.errors.phoneNumber}
                    </Typography>
                  ) : null}
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <textarea
                type="text"
                className="customInput customTextarea"
                placeholder="Address"
                name="address"
                onChange={(e) => {
                  const { value } = e.target;
                  if (value.trimStart() !== value) {
                    e.target.value = value.trimStart();
                  }
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.address}
                onKeyUp={formik.handleBlur}
                invalid={
                  formik.touched.address && formik.errors.address ? true : false
                }
                style={{
                  padding: "20px",
                  borderRadius: "8px",
                  outline: "none",
                  resize: "none",
                  border: `1px solid ${formik.touched.address && formik.errors.address
                    ? "red"
                    : "#DDDDDD"
                    }`,
                  width: "100%",
                }}
              />
              {formik.touched.address && formik.errors.address ? (
                <Typography
                  sx={{
                    color: "red",
                    fontSize: "14px",
                    marginTop: "4px",
                    paddingLeft: "10px",
                  }}
                >
                  {formik.errors.address}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#000000",
                  textAlign: "left",
                  padding: "0px 0px 0px 0px",
                }}
              >
                Authentication Details
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {isLoading || busy ? (
                <Skeleton height={48} />
              ) : (
                <>
                  <input
                    type="text"
                    name="email"
                    placeholder="Email Address"
                    value={formik.values.email}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.trimStart() !== value) {
                        e.target.value = value.trimStart();
                      }
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    style={{
                      padding: "20px",
                      borderRadius: "8px",
                      outline: "none",
                      border: `1px solid ${formik.touched.email && formik.errors.email
                        ? "red"
                        : "#DDDDDD"
                        }`,
                      width: "100%",
                    }}
                    readOnly={true}
                    disabled={true}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <Typography
                      fontSize={14}
                      fontWeight={500}
                      mt={1}
                      color={"red"}
                    >
                      {formik.errors.email}
                    </Typography>
                  ) : null}
                </>
              )}
            </Grid>
            {typeCheck !== "edit" && (
              <Grid item xs={12}>
                {isLoading || busy ? (
                  <Skeleton height={48} />
                ) : (
                  <Box
                    sx={{
                      position: "relative",
                    }}
                  >
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Password"
                      value={formik.values.password}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.trimStart() !== value) {
                          e.target.value = value.trimStart();
                        }
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      style={{
                        padding: "20px 50px 20px 20px",
                        borderRadius: "8px",
                        outline: "none",
                        border: `1px solid ${formik.touched.password && formik.errors.password
                          ? "red"
                          : "#DDDDDD"
                          }`,
                        width: "100%",
                      }}
                      autoComplete="new-Password"
                      onKeyUp={formik.handleBlur}
                    />
                    <IconButton
                      sx={{
                        position: "absolute",
                        right: "10px",
                        top: "11px",
                      }}
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                    {formik.touched.password && formik.errors.password ? (
                      <Typography
                        fontSize={14}
                        fontWeight={500}
                        mt={1}
                        color={"red"}
                      >
                        {formik.errors.password}
                      </Typography>
                    ) : null}
                  </Box>
                )}
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#000000",
                  textAlign: "left",
                  padding: "0px 0px 0px 0px",
                }}
              >
                Legal Information
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {isLoading || busy ? (
                <Skeleton height={48} />
              ) : (
                <input
                  type="text"
                  name="taxationNumber"
                  placeholder="Taxation Number"
                  value={formik.values.taxationNumber}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.trimStart() !== value) {
                      e.target.value = value.trimStart();
                    }
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  style={{
                    padding: "20px",
                    borderRadius: "8px",
                    outline: "none",
                    border: `1px solid ${formik.touched.taxationNumber &&
                      formik.errors.taxationNumber
                      ? "red"
                      : "#DDDDDD"
                      }`,
                    width: "100%",
                  }}
                  onKeyUp={formik.handleBlur}
                />
              )}
              {formik.touched.taxationNumber && formik.errors.taxationNumber ? (
                <Typography fontSize={14} fontWeight={500} mt={1} color={"red"}>
                  {formik.errors.taxationNumber}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              {isLoading || busy ? (
                <Skeleton height={150} />
              ) : (
                <>
                  <Box
                    sx={{
                      width: "100%",
                      height: "150px",
                      overflow: "hidden",
                      background: "#F4F4F4",
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border:
                        formik.touched.idCard && formik.errors.idCard
                          ? "1px solid red"
                          : null,
                    }}
                  >
                    {!idCardName && (
                      <label htmlFor="id-card">
                        <Button
                          component="span"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            outline: "none",
                            border: "none",
                            backgroundColor: "transparent",
                            textTransform: "capitalize",
                          }}
                          ref={uploadIdCardCoverButtonRef}
                          onKeyDown={handleIdCardTabKeyPress}
                          type="button"
                        >
                          <img
                            src={UPLOAD_GALLERY_ICON}
                            alt="Upload Icon"
                            effect="blur"
                            width={"42px"}
                          />
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: "14px",
                              fontWeight: "600",
                              color: "#000000",
                              margin: "8px 0px !important",
                            }}
                          >
                            Upload ID Card
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: "14px",
                              fontWeight: "600",
                              color: "#9C9C9C",
                            }}
                          >
                            {`(PSD,DOC,PDF)`}
                          </Typography>
                          <input
                            accept=".pdf,.psd,.doc,.docx,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/vnd.adobe.photoshop"
                            style={{ display: "none" }}
                            id="id-card"
                            type="file"
                            onChange={handleIdCardOnChange}
                          />
                        </Button>
                      </label>
                    )}
                    {idCardName && (
                      <Box
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <img
                          src={UPLOAD_GALLERY_ICON}
                          alt="Upload Icon"
                          effect="blur"
                          width={"42px"}
                        />
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#000000",
                            padding: "5px 0px",
                          }}
                        >
                          {idCardName}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#9C9C9C",
                          }}
                        >
                          Successfully Uploaded
                        </Typography>
                        <label htmlFor="id-card">
                          <IconButton
                            component="span"
                            sx={{
                              width: "40px",
                              height: "40px",
                              borderRadius: "16px",
                              backgroundColor: "#ffffff",
                              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                              transition: "0.5s",
                              position: "absolute",
                              right: "5px",
                              top: "5px",
                              "&:hover": {
                                backgroundColor: "#ffffff",
                                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                              },
                            }}
                          >
                            <EditIcon
                              sx={{
                                color: "#46ADF2",
                                fontSize: "26px",
                              }}
                            />
                          </IconButton>
                          <input
                            accept=".pdf,.psd,.doc,.docx,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/vnd.adobe.photoshop"
                            style={{ display: "none" }}
                            id="id-card"
                            type="file"
                            onChange={handleCoverImageOnChange}
                          />
                          <IconButton
                            component="span"
                            sx={{
                              width: "40px",
                              height: "40px",
                              borderRadius: "16px",
                              backgroundColor: "#ffffff",
                              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                              transition: "0.5s",
                              position: "absolute",
                              right: "50px",
                              top: "5px",
                              "&:hover": {
                                backgroundColor: "#ffffff",
                                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                              },
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setIdCard(null);
                              setIdCardName(null);
                            }}
                          >
                            <CloseIcon
                              sx={{
                                color: "#46ADF2",
                                fontSize: "26px",
                              }}
                            />
                          </IconButton>
                        </label>
                      </Box>
                    )}
                  </Box>
                </>
              )}
              {formik.touched.idCard && formik.errors.idCard ? (
                <Typography fontSize={14} fontWeight={500} mt={1} color={"red"}>
                  Upload ID Card Field Is Required
                </Typography>
              ) : null}
            </Grid>
          </Grid>
        </form>
        {typeCheck === "edit" && (
          <Box
            sx={{
              textAlign: "center",
              marginTop: "24px",
            }}
          >
            <CustomButton
              type="button"
              variant="contained"
              fullWidth
              onClick={() => handleResetPasswordPartner()}
              loading={isLoadingPassword}
              sx={{
                fontWeight: "700",
                fontSize: "18px",
                height: "56px",
                maxWidth: "160px",
                margin: "0 auto",
                textTransform: "unset",
                fontFamily: "Inter",
                background: "#00E676",
                "&:hover": {
                  background: "#00E676",
                },
              }}
              btnTitle="Reset Password"
            />
          </Box>
        )}
      </Box>
    </Fragment>
  );
};

export default PartnerAddManagement;
