import { Box, Typography, Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import AxiosInstance from "../../../Config/AxiosInstance";
import Swal from "sweetalert2";
import CustomDropdown from "../../../Components/CustomDropdown/CustomDropdown";
const PriceManagement = () => {
  const [priceRatio, setPriceRatio] = useState([]);
  const [adminValue, setAdminValue] = useState("");
  const [partnerValue, setPartnerValue] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [texiRatio, setTexiRatio] = useState([]);
  const [minimum_fare, setMinimum_fare] = useState("");
  const [fare_per_km, setFare_per_km] = useState("");
  const [waiting_charge, setWaiting_charge] = useState("");
  const [vehicleList, setVehicleList] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [editTexiMode, setEditTexiMode] = useState(false);

  const [d_Min_Fare, setD_Min_Fare] = useState("");
  const [d_Fare_Per_KM, setD_Fare_Per_KM] = useState("");
  const [d_Waiting_Charge, setD_Waiting_Charge] = useState("");
  const [editModeAllVehicles, setEditModeAllVehicles] = useState(false);
  const getPriceManagement = async () => {
    try {
      const response = await AxiosInstance.get(
        "/price-management/partnership/get-ratio"
      );
      if (response && response?.data?.s === 1) {
        setPriceRatio(response?.data?.r);
        setAdminValue(
          response?.data?.r.find((item) => item.type === 1)?.deal || ""
        );
        setPartnerValue(
          response?.data?.r.find((item) => item.type === 2)?.deal || ""
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const getFareManagement = async () => {
    try {
      const response = await AxiosInstance.get(
        `/price-management/taxi-fare/get-fare?vehicle_id=${selectedVehicle?.id}`
      );
      if (response && response?.data?.s === 1) {
        setTexiRatio(response?.data?.r);
        setMinimum_fare(response?.data?.r.minimum_fare || "");
        setFare_per_km(response?.data?.r.fare_per_km || "");
        setWaiting_charge(response?.data?.r.waiting_charge || "");
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getPriceManagement();
    getChooseVehicleList();
  }, []);
  useEffect(() => {
    if (selectedVehicle) {
      getFareManagement(selectedVehicle?.id);
    }
  }, [selectedVehicle]);
  const handleEditClick = () => {
    setEditMode(!editMode);
  };
  const handleSubmit = async () => {
    if (editMode) {
      if (parseFloat(adminValue) <= 0 || parseFloat(partnerValue) <= 0) {
        Swal.fire({
          position: "center",
          icon: "warning",
          showConfirmButton: false,
          timer: 2000,
          title: "Warning",
          text: "Please insert values greater than 0",
        });
      } else if (!String(adminValue).trim() || !String(partnerValue).trim()) {
        Swal.fire({
          position: "center",
          icon: "warning",
          showConfirmButton: false,
          timer: 2000,
          title: "Warning",
          text: "Please fill in all fields",
        });
      } else {
        try {
          if (adminValue !== priceRatio.find((item) => item.type === 1)?.deal) {
            const formDataAdmin = new FormData();
            formDataAdmin.append("type", 1);
            formDataAdmin.append("deal", adminValue);
            const responseAdmin = await AxiosInstance.post(
              "/price-management/partnership/update-ratio",
              formDataAdmin
            );
            if (responseAdmin && responseAdmin.data.s === 1) {
              Swal.fire({
                position: "center",
                icon: "success",
                showConfirmButton: false,
                timer: 2000,
                title: "Success",
                text: responseAdmin.data.m,
              });
              getPriceManagement();
              setEditMode(false);
            }
          }
          if (
            partnerValue !== priceRatio.find((item) => item.type === 2)?.deal
          ) {
            const formDataPartner = new FormData();
            formDataPartner.append("type", 2);
            formDataPartner.append("deal", partnerValue);
            const responsePartner = await AxiosInstance.post(
              "/price-management/partnership/update-ratio",
              formDataPartner
            );
            if (responsePartner && responsePartner.data.s === 1) {
              Swal.fire({
                position: "center",
                icon: "success",
                showConfirmButton: false,
                timer: 2000,
                title: "Success",
                text: responsePartner.data.m,
              });
              getPriceManagement();
              setEditMode(false);
            }
          }
        } catch (error) {
          console.log("error", error);
          setEditMode(false);
        }
      }
    } else {
      Swal.fire({
        position: "center",
        icon: "warning",
        showConfirmButton: false,
        timer: 2000,
        title: "Warning",
        text: "Please Change Any Field",
      });
    }
  };
  const handleEditTexi = () => {
    setEditTexiMode(!editTexiMode);
  };
  const handleTexiSubmit = async () => {
    if (
      parseFloat(minimum_fare) <= 0 ||
      parseFloat(fare_per_km) <= 0 ||
      parseFloat(waiting_charge) <= 0 ||
      parseFloat(d_Min_Fare) <= 0 ||
      parseFloat(d_Fare_Per_KM) <= 0 ||
      parseFloat(d_Waiting_Charge) <= 0
    ) {
      Swal.fire({
        position: "center",
        icon: "warning",
        showConfirmButton: false,
        timer: 2000,
        title: "Warning",
        text: "Please insert values greater than 0",
      });
    } else if (
      !String(minimum_fare).trim() ||
      !String(fare_per_km).trim() ||
      !String(waiting_charge).trim() ||
      !String(d_Min_Fare).trim() ||
      !String(d_Fare_Per_KM).trim() ||
      !String(d_Waiting_Charge).trim() ||
      !selectedVehicle
    ) {
      Swal.fire({
        position: "center",
        icon: "warning",
        showConfirmButton: false,
        timer: 2000,
        title: "Warning",
        text: "Please fill in all fields",
      });
    } else {
      try {
        const formDataAdmin = new FormData();
        formDataAdmin.append("vehicle_id", selectedVehicle.id);
        formDataAdmin.append("minimum_fare", minimum_fare);
        formDataAdmin.append("fare_per_km", fare_per_km);
        formDataAdmin.append("waiting_charge", waiting_charge);
        formDataAdmin.append("p_minimum_fare", d_Min_Fare);
        formDataAdmin.append("p_fare_per_km", d_Fare_Per_KM);
        formDataAdmin.append("p_waiting_charge", d_Waiting_Charge);
        const responseAdmin = await AxiosInstance.post("/price-management/taxi-fare/update-fare", formDataAdmin);
        if (responseAdmin && responseAdmin.data.s === 1) {
          Swal.fire({
            position: "center",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
            title: "Success",
            text: responseAdmin.data.m,
          });
          getFareManagement();
          setEditTexiMode(false);
          setSelectedVehicle(null)
        }
      } catch (error) {
        console.log("error", error);
        setEditMode(false);
      }
    }
  };
  const getChooseVehicleList = async () => {
    try {
      const response = await AxiosInstance.get(`/service/vehicle/get-all`);
      if (response && response?.data?.s === Number(1)) {
        const responseData = response?.data?.r;
        setVehicleList(responseData);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleSelectVehicle = (evt) => {
    setSelectedVehicle(evt);
    if (selectedVehicle) {
      getFareManagement(selectedVehicle?.id);
    }
  };
  // === App Vehicle ===
  const handleEditAllVehicles = () => {
    setEditModeAllVehicles(!editModeAllVehicles);
  };
  const handleAllVehiclesSubmit = async () => {
    if (
      parseFloat(d_Min_Fare) <= 0 ||
      parseFloat(d_Fare_Per_KM) <= 0 ||
      parseFloat(d_Waiting_Charge) <= 0
    ) {
      Swal.fire({
        position: "center",
        icon: "warning",
        showConfirmButton: false,
        timer: 2000,
        title: "Warning",
        text: "Please insert values greater than 0",
      });
    } else if (
      !String(d_Min_Fare).trim() ||
      !String(d_Fare_Per_KM).trim() ||
      !String(d_Waiting_Charge).trim()
    ) {
      Swal.fire({
        position: "center",
        icon: "warning",
        showConfirmButton: false,
        timer: 2000,
        title: "Warning",
        text: "Please fill in all fields",
      });
    } else if (vehicleList.length === 0) {
      Swal.fire({
        position: "center",
        icon: "warning",
        showConfirmButton: false,
        timer: 2000,
        title: "Warning",
        text: "No vehicles available to update",
      });
    } else {
      try {
        const responses = await Promise.all(
          vehicleList.map(async (vehicle) => {
            const formDataAdmin = new FormData();
            formDataAdmin.append("vehicle_id", vehicle?.id);
            formDataAdmin.append("p_minimum_fare", d_Min_Fare);
            formDataAdmin.append("p_fare_per_km", d_Fare_Per_KM);
            formDataAdmin.append("p_waiting_charge", d_Waiting_Charge);
            return AxiosInstance.post("/price-management/taxi-fare/update-fare", formDataAdmin);
          })
        );
        const success = responses.every(response => response?.data?.s === 1);
        if (success) {
          Swal.fire({
            position: "center",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
            title: "Success",
            text: "Vehicle fares updated successfully",
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
            title: "Error",
            text: "Error updating vehicle fares.",
          });
        }
        setEditModeAllVehicles(false);
        getChooseVehicleList();
      } catch (error) {
        console.log("Error submitting vehicle fares", error);
        setEditModeAllVehicles(false);
      }
    }
  };
  const getFareAllVehicleManagement = async () => {
    try {
      const response = await AxiosInstance.get(`/price-management/taxi-fare/get-fare?vehicle_id=${vehicleList[0]?.id}`);
      if (response && response?.data?.s === 1) {
        setD_Min_Fare(response?.data?.r?.p_minimum_fare || "");
        setD_Fare_Per_KM(response?.data?.r?.p_fare_per_km || "");
        setD_Waiting_Charge(response?.data?.r?.p_waiting_charge || "");
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getFareAllVehicleManagement(vehicleList[0]?.id);
  }, [vehicleList[0]?.id]);
  return (
    <Box
      sx={{
        width: "100%",
        padding: "0px 15px",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          color: "#161616",
          fontSize: "26px",
          fontWeight: "700",
          margin: "0px",
          padding: "0px",
          fontFamily: "Poppins",
          textAlign: "left",
          paddingBottom: "27px",
        }}
      >
        Price Management
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6} xl={4}>
          <Box
            sx={{
              backgroundColor: "#fff",
              boxShadow: "0px 4px 11.4px 0px rgba(0, 0, 0, 0.15)",
              borderRadius: "10px",
              padding: "20px 20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
                width: "100%",
                padding: "0px 0px 15px 0px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: "#161616",
                  fontSize: "18px",
                  fontWeight: "600",
                  margin: "0px",
                  padding: "0px",
                  fontFamily: "Poppins",
                }}
              >
                Default partnership ratio
              </Typography>
              <IconButton
                sx={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "16px",
                  backgroundColor: "#ffffff",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                  transition: "0.5s",
                  "&:hover": {
                    backgroundColor: "#ffffff",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                  },
                }}
              >
                {editMode ? (
                  <DoneIcon
                    onClick={handleSubmit}
                    sx={{
                      color: "#142328",
                      fontSize: "26px",
                    }}
                  />
                ) : (
                  <EditIcon
                    onClick={handleEditClick}
                    sx={{
                      color: "#142328",
                      fontSize: "26px",
                    }}
                  />
                )}
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                flexDirection: "column",
                paddingTop: "15px",
                gridRowGap: "20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gridGap: "10px",
                  width: "100%",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: "#161616",
                    fontSize: "16px",
                    fontWeight: "400",
                    margin: "0px",
                    padding: "0px",
                    fontFamily: "Poppins",
                  }}
                >
                  Between Admin and Partner
                </Typography>
                <input
                  value={adminValue}
                  onChange={(e) => setAdminValue(e.target.value)}
                  type="number"
                  min="0"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-"].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  readOnly={!editMode}
                  style={{
                    color: "#161616",
                    fontSize: "16px",
                    fontWeight: "400",
                    margin: "0px",
                    padding: "5px 10px",
                    fontFamily: "Poppins",
                    background: !editMode ? "rgba(0, 230, 118, 0.25)" : "#fff",
                    borderRadius: "5px",
                    outline: "none",
                    width: "80px",
                    border: `${!editMode ? "none" : "1px solid #000"}`,
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gridGap: "10px",
                  width: "100%",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: "#161616",
                    fontSize: "16px",
                    fontWeight: "400",
                    margin: "0px",
                    padding: "0px",
                    fontFamily: "Poppins",
                  }}
                >
                  Between Partner and Driver
                </Typography>
                <input
                  type="number"
                  min="0"
                  inputMode="numeric" // Adding inputMode attribute
                  pattern="[0-9]*" // Adding pattern attribute to allow only numeric input
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-"].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  value={partnerValue}
                  onChange={(e) => setPartnerValue(e.target.value)}
                  readOnly={!editMode}
                  style={{
                    color: "#161616",
                    fontSize: "16px",
                    fontWeight: "400",
                    margin: "0px",
                    padding: "5px 10px",
                    fontFamily: "Poppins",
                    background: !editMode ? "rgba(0, 230, 118, 0.25)" : "#fff",
                    borderRadius: "5px",
                    outline: "none",
                    width: "80px",
                    border: `${!editMode ? "none" : "1px solid #000"}`,
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gridGap: "10px",
                  width: "100%",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: "#161616",
                    fontSize: "14px",
                    fontWeight: "400",
                    margin: "0px",
                    padding: "0px",
                    fontFamily: "Poppins",
                    textAlign: "left",
                  }}
                >
                  <Typography
                    variant="body1"
                    component={"span"}
                    sx={{
                      color: "red",
                    }}
                  >
                    *
                  </Typography>
                  This will be the default partnership ratio between users if
                  you want specific partnership ratio you can update it from
                  user profile
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6} xl={4}>
          <Box
            sx={{
              backgroundColor: "#fff",
              boxShadow: "0px 4px 11.4px 0px rgba(0, 0, 0, 0.15)",
              borderRadius: "10px",
              padding: "20px 20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
                width: "100%",
                padding: "0px 0px 15px 0px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: "#161616",
                  fontSize: "18px",
                  fontWeight: "600",
                  margin: "0px",
                  padding: "0px",
                  fontFamily: "Poppins",
                }}
              >
                TAXI Charges
              </Typography>
              <IconButton
                sx={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "16px",
                  backgroundColor: "#ffffff",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                  transition: "0.5s",
                  "&:hover": {
                    backgroundColor: "#ffffff",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                  },
                }}
              >
                {editTexiMode ? (
                  <DoneIcon
                    onClick={handleTexiSubmit}
                    sx={{
                      color: "#142328",
                      fontSize: "26px",
                    }}
                  />
                ) : (
                  <EditIcon
                    onClick={handleEditTexi}
                    sx={{
                      color: "#142328",
                      fontSize: "26px",
                    }}
                  />
                )}
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                flexDirection: "column",
                paddingTop: "15px",
                gridRowGap: "20px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <CustomDropdown
                  selectedValue={selectedVehicle}
                  dropDownItems={vehicleList?.map((v, i) => ({
                    id: v?.id,
                    value: v?.vehicle_type,
                    label: v?.vehicle_type,
                    key: i,
                  }))}
                  placeholder={"Choose vehicle"}
                  onSelect={(evt) => {
                    handleSelectVehicle(evt);
                  }}
                  disabled={!editTexiMode}
                />
              </Box>
              {selectedVehicle && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gridGap: "10px",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#161616",
                        fontSize: "16px",
                        fontWeight: "400",
                        margin: "0px",
                        padding: "0px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Minimum Fare
                    </Typography>
                    <input
                      value={minimum_fare}
                      onChange={(e) => setMinimum_fare(e.target.value)}
                      type="number"
                      min="0"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      readOnly={!selectedVehicle}
                      style={{
                        color: "#161616",
                        fontSize: "16px",
                        fontWeight: "400",
                        margin: "0px",
                        padding: "5px 10px",
                        fontFamily: "Poppins",
                        background: !selectedVehicle
                          ? "rgba(0, 230, 118, 0.25)"
                          : "#fff",
                        borderRadius: "5px",
                        outline: "none",
                        width: "80px",
                        border: `${!selectedVehicle ? "none" : "1px solid #000"
                          }`,
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gridGap: "10px",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#161616",
                        fontSize: "16px",
                        fontWeight: "400",
                        margin: "0px",
                        padding: "0px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Fare per KM
                    </Typography>
                    <input
                      value={fare_per_km}
                      onChange={(e) => setFare_per_km(e.target.value)}
                      type="number"
                      min="0"
                      inputMode="numeric" // Adding inputMode attribute
                      pattern="[0-9]*" // Adding pattern attribute to allow only numeric input
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      readOnly={!selectedVehicle}
                      style={{
                        color: "#161616",
                        fontSize: "16px",
                        fontWeight: "400",
                        margin: "0px",
                        padding: "5px 10px",
                        fontFamily: "Poppins",
                        background: !selectedVehicle
                          ? "rgba(0, 230, 118, 0.25)"
                          : "#fff",
                        borderRadius: "5px",
                        outline: "none",
                        width: "80px",
                        border: `${!selectedVehicle ? "none" : "1px solid #000"
                          }`,
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gridGap: "10px",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#161616",
                        fontSize: "16px",
                        fontWeight: "400",
                        margin: "0px",
                        padding: "0px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Waiting charge
                    </Typography>
                    <input
                      value={waiting_charge}
                      onChange={(e) => setWaiting_charge(e.target.value)}
                      type="number"
                      min="0"
                      inputMode="numeric" // Adding inputMode attribute
                      pattern="[0-9]*" // Adding pattern attribute to allow only numeric input
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      readOnly={!selectedVehicle}
                      style={{
                        color: "#161616",
                        fontSize: "16px",
                        fontWeight: "400",
                        margin: "0px",
                        padding: "5px 10px",
                        fontFamily: "Poppins",
                        background: !selectedVehicle
                          ? "rgba(0, 230, 118, 0.25)"
                          : "#fff",
                        borderRadius: "5px",
                        outline: "none",
                        width: "80px",
                        border: `${!selectedVehicle ? "none" : "1px solid #000" }`,
                        
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gridGap: "10px",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#161616",
                        fontSize: "14px",
                        fontWeight: "400",
                        margin: "0px",
                        padding: "0px",
                        fontFamily: "Poppins",
                        textAlign: "left",
                        display: "flex",
                      }}
                    >
                      <Typography
                        variant="body1"
                        component={"span"}
                        sx={{
                          color: "red",
                        }}
                      >
                        *
                      </Typography>
                      Taxi fare will be calculated according to KM but if total
                      sum of ride is less then minimum fare will be considered
                      as Taxi fare
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6} xl={4}>
          <Box
            sx={{
              backgroundColor: "#fff",
              boxShadow: "0px 4px 11.4px 0px rgba(0, 0, 0, 0.15)",
              borderRadius: "10px",
              padding: "20px 20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
                width: "100%",
                padding: "0px 0px 15px 0px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: "#161616",
                  fontSize: "18px",
                  fontWeight: "600",
                  margin: "0px",
                  padding: "0px",
                  fontFamily: "Poppins",
                }}
              >
                Package Charges
              </Typography>
              <IconButton
                sx={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "16px",
                  backgroundColor: "#ffffff",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                  transition: "0.5s",
                  "&:hover": {
                    backgroundColor: "#ffffff",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                  },
                }}
              >
                {editModeAllVehicles ? (
                  <DoneIcon
                    onClick={handleAllVehiclesSubmit}
                    sx={{
                      color: "#142328",
                      fontSize: "26px",
                    }}
                  />
                ) : (
                  <EditIcon
                    onClick={handleEditAllVehicles}
                    sx={{
                      color: "#142328",
                      fontSize: "26px",
                    }}
                  />
                )}
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                flexDirection: "column",
                paddingTop: "15px",
                gridRowGap: "20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gridGap: "10px",
                  width: "100%",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: "#161616",
                    fontSize: "16px",
                    fontWeight: "400",
                    margin: "0px",
                    padding: "0px",
                    fontFamily: "Poppins",
                  }}
                >
                  Minimum fare
                </Typography>
                <input
                  value={d_Min_Fare}
                  onChange={(e) => setD_Min_Fare(e.target.value)}
                  type="number"
                  min="0"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-"].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  readOnly={!editModeAllVehicles}
                  style={{
                    color: "#161616",
                    fontSize: "16px",
                    fontWeight: "400",
                    margin: "0px",
                    padding: "5px 10px",
                    fontFamily: "Poppins",
                    
                    borderRadius: "5px",
                    outline: "none",
                    width: "80px",
                    border: `${!editModeAllVehicles ? "none" : "1px solid #000" }`,
                    background: !editModeAllVehicles
                      ? "rgba(0, 230, 118, 0.25)"
                      : "#fff",
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gridGap: "10px",
                  width: "100%",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: "#161616",
                    fontSize: "16px",
                    fontWeight: "400",
                    margin: "0px",
                    padding: "0px",
                    fontFamily: "Poppins",
                  }}
                >
                  Fare per KM
                </Typography>
                <input
                  value={d_Fare_Per_KM}
                  onChange={(e) => setD_Fare_Per_KM(e.target.value)}
                  type="number"
                  min="0"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-"].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  readOnly={!editModeAllVehicles}
                  style={{
                    color: "#161616",
                    fontSize: "16px",
                    fontWeight: "400",
                    margin: "0px",
                    padding: "5px 10px",
                    fontFamily: "Poppins",
                    borderRadius: "5px",
                    outline: "none",
                    width: "80px",
                    border: `${!editModeAllVehicles ? "none" : "1px solid #000" }`,
                    background: !editModeAllVehicles
                      ? "rgba(0, 230, 118, 0.25)"
                      : "#fff",
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gridGap: "10px",
                  width: "100%",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: "#161616",
                    fontSize: "16px",
                    fontWeight: "400",
                    margin: "0px",
                    padding: "0px",
                    fontFamily: "Poppins",
                  }}
                >
                  Waiting charge
                </Typography>
                <input
                  value={d_Waiting_Charge}
                  onChange={(e) => setD_Waiting_Charge(e.target.value)}
                  type="number"
                  min="0"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-"].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  readOnly={!editModeAllVehicles}
                  style={{
                    color: "#161616",
                    fontSize: "16px",
                    fontWeight: "400",
                    margin: "0px",
                    padding: "5px 10px",
                    fontFamily: "Poppins",
                    borderRadius: "5px",
                    outline: "none",
                    width: "80px",
                    border: `${!editModeAllVehicles ? "none" : "1px solid #000" }`,
                    background: !editModeAllVehicles
                      ? "rgba(0, 230, 118, 0.25)"
                      : "#fff",
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gridGap: "10px",
                  width: "100%",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: "#161616",
                    fontSize: "14px",
                    fontWeight: "400",
                    margin: "0px",
                    padding: "0px",
                    fontFamily: "Poppins",
                    textAlign: "left",
                    display: "flex",
                  }}
                >
                  <Typography
                    variant="body1"
                    component={"span"}
                    sx={{
                      color: "red",
                    }}
                  >
                    *
                  </Typography>
                  Lorem ipsum dolor sit amet consectetur. Felis nunc egestas
                  purus elit feugiat porta vitae. Amet consequat commodo lacus
                  venenatis consequat
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PriceManagement;
