import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
import {getAuth} from "firebase/auth"
import { getFirestore } from "firebase/firestore";
const firebaseConfig = {
    apiKey: "AIzaSyC5ITu22aDiyJ7gMXZ7Jd1yS4AR6AJK0_g",
    authDomain: "yandi-803b7.firebaseapp.com",
    projectId: "yandi-803b7",
    storageBucket: "yandi-803b7.appspot.com",
    messagingSenderId: "519771258014",
    appId: "1:519771258014:web:cd817ae26e3ad74dce0f8f",
    measurementId: "G-YRJYZ6M1B7"
};
const app = initializeApp(firebaseConfig);
export const AUTH_LOGIN = getAuth(app);
export const messaging = getMessaging(app);
export const DATABASE = getFirestore(app);
