import React, { Fragment, useState } from "react";
import {
  Grid,
  Card,
  Box,
  TextField,
  Typography,
  Paper,
} from "@mui/material";
import admin_login from "../../../Assets/Images/loginBg.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomButton from "../../../Components/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";
import AxiosInstance from "../../../Config/AxiosInstance";
import Swal from "sweetalert2";
const ForgotPassword = () => {
  const navigate = useNavigate();
  const [busy, setBusy] = useState(false);
  const ForgotPasswordValidation = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Please Enter Your Email Address")
        .email("Please Provide A Valid Email Address"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const params = {
        email: values.email,
        password: values.password,
      };
      setBusy(true);
      try {
        const response = await AxiosInstance.post("/auth/forgot-password",params);
        if (response && response?.data.s === 1) {
          navigate("/verification");
          localStorage.setItem("info", JSON.stringify(response?.data?.r));
          Swal.fire({
            position: "center",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
            title: "success",
            text: response?.data?.m,
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
            title: "error",
            text: response?.data?.m,
          });
        }
      } catch (error) {
        console.log('error', error);
      } finally {
        setBusy(false);
        resetForm();
      }
    },
  });
  return (
    <Fragment>
      <Grid container component="main" sx={{ height: "100%" }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${admin_login})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></Grid>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Card
              variant="outlined"
              sx={{
                width: "646px",
                position: "relative",
                right: { sm: "30px", md: "60px", lg: "100px", xl: "150px" },
                borderRadius: "30px",
              }}
            >
              <Box sx={{ padding: "40px 60px" }}>
                {" "}
                <Typography level="h1" fontSize={42} fontWeight={700}>
                  Forgot Password?
                </Typography>
                <Typography fontSize={16} fontWeight={400}>
                  Provide your email to recover your password.
                </Typography>
                <form
                  sx={{
                    width: "100%",
                  }}
                  onSubmit={(e) => {
                    e.preventDefault();
                    ForgotPasswordValidation.handleSubmit();
                    return false;
                  }}
                >
                  <Box sx={{ marginTop: "20px" }}>
                    <Typography fontSize={14} fontWeight={600} color="#000000">
                      Email
                    </Typography>
                    <TextField
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: `${ForgotPasswordValidation.touched.email &&
                                ForgotPasswordValidation.errors.email
                                ? "1px solid red"
                                : "none"
                              }`, // Corrected line
                            boxShadow: "0px 4px 4px 0px #00000040",
                            height: "64px",
                          },
                        },
                        marginTop: "5px",
                      }}
                      type="text"
                      placeholder="example@email.com"
                      name="email"
                      onChange={ForgotPasswordValidation.handleChange}
                      onBlur={ForgotPasswordValidation.handleBlur}
                      value={ForgotPasswordValidation.values.email}
                      onKeyUp={ForgotPasswordValidation.handleBlur}
                      invalid={
                        ForgotPasswordValidation.touched.email &&
                          ForgotPasswordValidation.errors.email
                          ? true
                          : false
                      }
                      fullWidth
                      id="outlined-required"
                    />
                    {ForgotPasswordValidation.touched.email &&
                      ForgotPasswordValidation.errors.email ? (
                      <Typography
                        fontSize={14}
                        fontWeight={500}
                        mt={1}
                        color={"red"}
                      >
                        {ForgotPasswordValidation.errors.email}
                      </Typography>
                    ) : null}
                  </Box>
                  <Box sx={{ textAlign: "center" }} mt={3}>
                    <CustomButton
                      variant="contained"
                      fullWidth
                      disabled={
                        !(
                          ForgotPasswordValidation.isValid &&
                          ForgotPasswordValidation.dirty
                        )
                      }
                      sx={{
                        fontWeight: "700",
                        fontSize: "18px",
                        height: "56px",
                        maxWidth: "261px",
                        textTransform: "unset",
                        fontFamily: "Inter",
                        backgroundColor: "#000000",
                      }}
                      btnTitle="Send Reset Password Link"
                      loading={busy}
                      type="submit"
                    />
                  </Box>
                </form>
              </Box>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ForgotPassword;
