import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import ReactInputMask from "react-input-mask";
import { useLocation, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import EditIcon from "@mui/icons-material/Edit";
import DUMMYAVTAR from "../../../../Assets/Images/avtarProfile.jpg";
import TopHeader from "../../../../Components/TopHeader/TopHeader";
import AxiosInstance from "../../../../Config/AxiosInstance";
import CustomModal from "../../../../Components/CustomModel/CustomModel";
import CustomButton from "../../../../Components/CustomButton/CustomButton";
import moment from "moment";
import { useInView } from "react-intersection-observer";
const PartnerManagementView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const [page, setPage] = useState(0);
  const { ref, inView } = useInView({
    threshold: 0.1,
  });
  const [viewPartnerDetails, setViewPartnerDetails] = useState({});
  const [showAddCredit, setShowAddCredit] = useState(false);
  const [showReleationModel, setShowReleationModel] = useState(false);
  const [paymentIsLoading, setPaymentIsLoading] = useState(false);
  const [partnerReleationShipID, setPartnerReleationShipID] = useState(null);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [currentType, setCurrentType] = useState();
  let lenght = 0;
  useEffect(() => {
    if (location?.state?.userId) {
      const viewPartnerId = location?.state?.userId;
      getDriversDetails(viewPartnerId);
      getTransactionHistory(viewPartnerId);
    }
  }, [location?.state?.driversId]);
  const getDriversDetails = async (viewPartnerId) => {
    try {
      const response = await AxiosInstance.get(
        `/user/get-by-id?user_id=${viewPartnerId}`
      );
      if (response && response?.data?.r) {
        setViewPartnerDetails(response?.data?.r);
        setPartnerReleationShipID(response?.data?.r?.releation);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: "",
    },
    validationSchema: Yup.object({
      amount: Yup.string()
        .matches(/^(\d+(\.\d{1,2})?)?$/, "Invalid Amount format")
        .required("Amount is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setPaymentIsLoading(true);
      if (paymentIsLoading) return;
      try {
        const formData = new FormData();
        formData.append("releation_id", partnerReleationShipID?.id);
        formData.append("amount", values.amount);
        const response = await AxiosInstance.post(
          `/price-management/add-partner-balance`,
          formData
        );
        if (response && response?.data?.r) {
          Swal.fire({
            position: "center",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
            title: "success",
            text: response?.data?.m,
          });
          setPaymentIsLoading(false);
          getDriversDetails(location?.state?.userId);
          getTransactionHistory(location?.state?.userId);
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
            title: "error",
            text: response?.data?.m,
          });
          setPaymentIsLoading(false);
          getDriversDetails(location?.state?.userId);
          getTransactionHistory(location?.state?.userId);
        }
      } catch (error) {
        setPaymentIsLoading(false);
      } finally {
        setShowAddCredit(false);
        resetForm();
        getDriversDetails(location?.state?.userId);
        getTransactionHistory(location?.state?.userId);
      }
    },
  });
  const updateReleationShip = useFormik({
    enableReinitialize: true,
    initialValues: {
      releations_id: partnerReleationShipID ? partnerReleationShipID?.id : "",
      deal_percentage: "",
    },
    validationSchema: Yup.object({
      releations_id: Yup.string()
        .matches(/^(\d+(\.\d{1,2})?)?$/, "Invalid releations_id format")
        .required("Releations id is required"),
      deal_percentage: Yup.string()
        .matches(
          /^(100(\.0{1,2})?|\d{1,2}(\.\d{1,2})?)%$/,
          "Invalid deal percentage format"
        )
        .required("Deal percentage is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      if (isLoading) return;
      try {
        const formData = new FormData();
        formData.append("releations_id", values.releations_id);
        const dealPercentage = values.deal_percentage.replace("%", "");
        formData.append("deal", dealPercentage);
        const response = await AxiosInstance.post(
          `/price-management/update-releation`,
          formData
        );
        if (response && response.data && response.data.s === 1) {
          Swal.fire({
            position: "center",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
            title: "Success",
            text: response.data.m,
          });
          getDriversDetails(location.state.userId);
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
            title: "Error",
            text: response.data.m,
          });
        }
      } catch (error) {
        Swal.fire({
          position: "center",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
          title: "Error",
          text: "An error occurred. Please try again later.",
        });
      } finally {
        setIsLoading(false);
        setShowReleationModel(false);
        resetForm();
      }
    },
  });
  const getTransactionHistory = async (viewPartnerId, type, count = 0) => {
    try {
      let response;
      if (type !== null) {
        response = await AxiosInstance.get(
          `/price-management/transaction-history`,
          {
            params: {
              user_id: location?.state?.userId,
              type: type,
              count: count,
            },
          }
        );
      } else {
        response = await AxiosInstance.get(
          `/price-management/transaction-history`,
          {
            params: { user_id: location?.state?.userId, count: count },
          }
        );
      }

      if (response && response?.data?.r) {
        setTransactionHistory((prev) =>
          prev.length > 0 ? [...prev, ...response.data.r] : response.data.r
        );
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setTransactionHistory([]);
  }, [currentType]);
  useEffect(() => {
    if (transactionHistory?.length > 29 && inView) {
      lenght = transactionHistory?.length;
      getTransactionHistory(lenght);
    }
  }, [inView]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box>
      <TopHeader title="Partner View" onClick={() => navigate(-1)} />
      <Box
        sx={{
          padding: "100px 15px 40px 15px",
          maxWidth: { xs: "100%", md: "100%", lg: "90%", xl: "90%" },
          margin: "0 auto",
          width: "100%",
        }}
      >
        {isLoading ? (
          <Grid container spacing={2}>
            {/* Skeleton for Card */}
            <Grid item xs={12}>
              <Card sx={{ backgroundColor: "#142328" }}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={3}>
                      <Skeleton height={417} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={9}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                          <Card sx={{ backgroundColor: "#ffffff" }}>
                            <CardContent>
                              <Typography variant="h6" gutterBottom>
                                <Skeleton width={150} />
                              </Typography>
                              <Grid container spacing={3}>
                                {[1, 2, 3, 4].map((index) => (
                                  <Grid key={index} item xs={12} md={3}>
                                    <Skeleton height={30} />
                                  </Grid>
                                ))}
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Card sx={{ backgroundColor: "#ffffff" }}>
                            <CardContent>
                              <Typography variant="h6" gutterBottom>
                                <Skeleton width={150} />
                              </Typography>
                              <Grid container spacing={3}>
                                {[1, 2, 3, 4].map((index) => (
                                  <Grid key={index} item xs={12} md={3}>
                                    <Skeleton height={30} />
                                  </Grid>
                                ))}
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Card sx={{ backgroundColor: "#ffffff" }}>
                            <CardContent>
                              <Typography variant="h6" gutterBottom>
                                <Skeleton width={150} />
                              </Typography>
                              <Grid container spacing={3}>
                                {[1, 2, 3, 4].map((index) => (
                                  <Grid key={index} item xs={12} md={3}>
                                    <Skeleton height={30} />
                                  </Grid>
                                ))}
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Card
                sx={{
                  backgroundColor: "#142328",
                  position: "relative",
                }}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={3}>
                      <Box
                        sx={{
                          height: "300px",
                        }}
                      >
                        <img
                          src={
                            viewPartnerDetails?.profile_img
                              ? `https://api.yandi.app${viewPartnerDetails?.profile_img}`
                              : DUMMYAVTAR
                          }
                          alt="driversProfile"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "10px",
                            objectPosition: "top",
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                          <Card
                            sx={{
                              backgroundColor: "#ffffff",
                            }}
                          >
                            <CardContent>
                              <Typography
                                sx={{
                                  fontSize: "18px",
                                  fontWeight: "600",
                                  color: "#000000",
                                  textAlign: "left",
                                  width: "100%",
                                  paddingBottom: "10px",
                                  fontFamily: "Poppins",
                                }}
                              >
                                Basic information
                              </Typography>
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={3}>
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    Partner Name
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      paddingTop: "4px",
                                      textTransform: "capitalize",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    {viewPartnerDetails?.full_name
                                      ? viewPartnerDetails?.full_name
                                      : "N?N"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    Email
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      paddingTop: "4px",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    {viewPartnerDetails?.email
                                      ? viewPartnerDetails?.email
                                      : "N?N"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    Phone Number
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      paddingTop: "4px",
                                      textTransform: "capitalize",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    {viewPartnerDetails?.phno
                                      ? `${viewPartnerDetails?.phno_cc} ${viewPartnerDetails?.phno}`
                                      : "N?N"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    Partner Code
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      paddingTop: "4px",
                                      textTransform: "capitalize",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    {viewPartnerDetails?.partner_code
                                      ? `${viewPartnerDetails?.partner_code}`
                                      : "-"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      wordBreak: "break-word",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    Address
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      paddingTop: "4px",
                                      textTransform: "capitalize",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    {viewPartnerDetails?.user_address?.length >
                                    0
                                      ? viewPartnerDetails?.user_address[0]
                                          ?.address
                                      : "N?N"}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Card
                            sx={{
                              backgroundColor: "#ffffff",
                            }}
                          >
                            <CardContent>
                              <Typography
                                sx={{
                                  fontSize: "18px",
                                  fontWeight: "600",
                                  color: "#000000",
                                  textAlign: "left",
                                  width: "100%",
                                  paddingBottom: "10px",
                                  fontFamily: "Poppins",
                                }}
                              >
                                Authentication Details
                              </Typography>
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={4}>
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    Email
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      paddingTop: "4px",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    {viewPartnerDetails?.email
                                      ? viewPartnerDetails?.email
                                      : "N/N"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    Password
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      paddingTop: "4px",
                                      textTransform: "capitalize",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    {viewPartnerDetails?.password
                                      ? "********"
                                      : "N/N"}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Card
                            sx={{
                              backgroundColor: "#ffffff",
                            }}
                          >
                            <CardContent>
                              <Typography
                                sx={{
                                  fontSize: "18px",
                                  fontWeight: "600",
                                  color: "#000000",
                                  textAlign: "left",
                                  width: "100%",
                                  paddingBottom: "10px",
                                  fontFamily: "Poppins",
                                }}
                              >
                                Legal Information
                              </Typography>
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={4} xl={3}>
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    Taxation Number
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      paddingTop: "4px",
                                      textTransform: "capitalize",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    {viewPartnerDetails?.user_document?.length >
                                    0
                                      ? JSON.parse(
                                          viewPartnerDetails?.user_document[0]
                                            ?.doc_reference
                                        )?.taxationNumber
                                      : "N/N"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={4} xl={3}>
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    Percentage Deal
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      paddingTop: "4px",
                                      textTransform: "capitalize",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    {viewPartnerDetails?.releation
                                      ? `${viewPartnerDetails?.releation.deal}%`
                                      : "0%"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={4} xl={3}>
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    ID Card
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      paddingTop: "4px",
                                      textTransform: "capitalize",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    {viewPartnerDetails?.user_document?.length >
                                    0 ? (
                                      <Button
                                        target="_blank"
                                        download
                                        href={`https://api.yandi.app${viewPartnerDetails?.user_document[0]?.document}`}
                                        sx={{
                                          width: "105px",
                                          marginTop: "4px",
                                          outline: "none",
                                          border: "none",
                                          backgroundColor: "#000000",
                                          color: "#ffffff",
                                          textTransform: "capitalize",
                                          fontFamily: "Poppins",
                                          "&:hover": {
                                            backgroundColor: "#000000",
                                            color: "#ffffff",
                                          },
                                        }}
                                      >
                                        Download
                                      </Button>
                                    ) : (
                                      "N?N"
                                    )}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={4} xl={3}>
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    Update Releation
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      paddingTop: "4px",
                                      textTransform: "capitalize",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Button
                                      type="button"
                                      onClick={() =>
                                        setShowReleationModel(true)
                                      }
                                      sx={{
                                        width: "150px",
                                        marginTop: "4px",
                                        outline: "none",
                                        border: "none",
                                        backgroundColor: "#000000",
                                        color: "#ffffff",
                                        textTransform: "capitalize",
                                        fontFamily: "Poppins",
                                        "&:hover": {
                                          backgroundColor: "#000000",
                                          color: "#ffffff",
                                        },
                                      }}
                                    >
                                      Update Releation
                                    </Button>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <IconButton
                  sx={{
                    position: "absolute",
                    bottom: "10px",
                    left: "10px",
                    width: "40px",
                    height: "40px",
                    backgroundColor: "#ffffff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "&:hover": {
                      backgroundColor: "#ffffff",
                    },
                  }}
                  onClick={() =>
                    navigate("/admin/partner/update", {
                      state: {
                        partnerId: viewPartnerDetails.id,
                        type: "edit",
                      },
                    })
                  }
                >
                  <EditIcon
                    sx={{
                      fontSize: "20px",
                      color: "#000000",
                    }}
                  />
                </IconButton>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontSiz: "20px",
                        color: "#142328",
                        fontWeight: "500",
                        fontFamily: "Poppins",
                      }}
                    >
                      Payment Information
                    </Typography>
                    {viewPartnerDetails?.releation?.deal !== undefined &&
                      viewPartnerDetails?.releation?.deal > 0 && (
                        <Button
                          type="button"
                          onClick={() => setShowAddCredit(true)}
                          sx={{
                            background: "#142328",
                            fontSize: "16px",
                            fontWeight: "600",
                            color: "#ffffff",
                            padding: "10px 16px",
                            borderRadius: "6px",
                            display: "flex",
                            alignItems: "center",
                            textTransform: "capitalize",
                            "&:hover": {
                              backgroundColor: "#00E676",
                              color: "#ffffff",
                            },
                          }}
                        >
                          Add Credit
                        </Button>
                      )}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Card
                        sx={{
                          backgroundColor: "#142328",
                          borderRadius: "15px",
                        }}
                      >
                        <CardContent>
                          <Box
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            <Typography
                              variant="h6"
                              component="h3"
                              sx={{
                                borderRadius: "10px",
                                fontSize: "18px",
                                fontWeight: "400",
                                color: "#ffffff",
                                margin: "0px 0px 10px 0px",
                                fontFamily: "Poppins",
                              }}
                            >
                              Total Payment Received
                            </Typography>
                            <Typography
                              variant="h5"
                              component="h2"
                              sx={{
                                fontSize: "18px",
                                fontWeight: "400",
                                color: "#ffffff",
                                fontFamily: "Poppins",
                              }}
                            >
                              {`GNF ${
                                viewPartnerDetails?.balance_details
                                  ?.total_payment_receive ?? 0
                              }`}
                            </Typography>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Card
                        sx={{
                          backgroundColor: "#142328",
                          borderRadius: "15px",
                        }}
                      >
                        <CardContent>
                          <Box
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            <Typography
                              variant="h6"
                              component="h3"
                              sx={{
                                borderRadius: "10px",
                                fontSize: "18px",
                                fontWeight: "400",
                                color: "#ffffff",
                                margin: "0px 0px 10px 0px",
                                fontFamily: "Poppins",
                              }}
                            >
                              Wallet Balance
                            </Typography>
                            <Typography
                              variant="h5"
                              component="h2"
                              sx={{
                                fontSize: "18px",
                                fontWeight: "400",
                                color: "#ffffff",
                                fontFamily: "Poppins",
                              }}
                            >
                              {`GNF ${
                                viewPartnerDetails?.balance_details
                                  ?.wallet_balance ?? 0
                              }`}
                            </Typography>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Tooltip title="Filter Payment History">
                      <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                      >
                        <FilterAltIcon
                          sx={{ width: 40, height: 40, color: "#000" }}
                        ></FilterAltIcon>
                      </IconButton>
                    </Tooltip>
                    <Menu
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={open}
                      onClose={handleClose}
                      onClick={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          mt: 1.5,
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          "&::before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                      <MenuItem
                        onClick={() => {
                          getTransactionHistory("", 2);
                          setCurrentType(2);
                          handleClose();
                        }}
                        sx={{
                          fontSize: "18px",
                          fontWeight: "600",
                          fontFamily: "Poppins",
                        }}
                      >
                        Resturant Delivery
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          getTransactionHistory("", 1);
                          setCurrentType(1);
                          handleClose();
                        }}
                        sx={{
                          fontSize: "18px",
                          fontWeight: "600",
                          fontFamily: "Poppins",
                        }}
                      >
                        Ride
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          getTransactionHistory("", 3);
                          setCurrentType(3);
                          handleClose();
                        }}
                        sx={{
                          fontSize: "18px",
                          fontWeight: "600",
                          fontFamily: "Poppins",
                        }}
                      >
                        Package Delivery
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          getTransactionHistory("", 4);
                          setCurrentType(4);
                          handleClose();
                        }}
                        sx={{
                          fontSize: "18px",
                          fontWeight: "600",
                          fontFamily: "Poppins",
                        }}
                      >
                        Credit
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          getTransactionHistory("", 5);
                          setCurrentType(5);
                          handleClose();
                        }}
                        sx={{
                          fontSize: "18px",
                          fontWeight: "600",
                          fontFamily: "Poppins",
                        }}
                      >
                        Debit
                      </MenuItem>
                    </Menu>
                  </Box>
                  <Box
                    sx={{
                      height: "400px",
                      overflowY: "auto",
                    }}
                  >
                    <Grid container spacing={2}>
                      {transactionHistory?.length > 0 ? (
                        transactionHistory?.map((elem, index) => {
                          return (
                            <>
                              {elem?.ref_id === null ? (
                                <Grid item xs={12} md={12} key={index}>
                                  <Box
                                    sx={{
                                      width: "100%",
                                      background: "#00E6761A",
                                      padding: "20px",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography
                                        variant="h6"
                                        sx={{
                                          fontSize: "18px",
                                          color: "#142328",
                                          fontWeight: "400",
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        {elem?.added_by_name}
                                      </Typography>
                                      <Typography
                                        variant="h6"
                                        sx={{
                                          fontSize: "18px",
                                          color: "#142328",
                                          fontWeight: "400",
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        {`GNF ${elem?.amount}`}
                                      </Typography>
                                    </Box>
                                    <Typography
                                      variant="h6"
                                      sx={{
                                        fontSize: "18px",
                                        color: "#142328",
                                        fontWeight: "400",
                                        marginTop: "10px",
                                        fontFamily: "Poppins",
                                      }}
                                    >
                                      {moment(elem?.datetime).format(
                                        "DD MMM YYYY, hh:mm A"
                                      )}
                                    </Typography>
                                  </Box>
                                </Grid>
                              ) : elem?.r_service_id === 1 ? (
                                <Grid item xs={12} md={12} key={index}>
                                  <Box
                                    sx={{
                                      width: "100%",
                                      background: "#00E6761A",
                                      padding: "20px",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        // alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography
                                        variant="h6"
                                        sx={{
                                          fontSize: "18px",
                                          color: "#142328",
                                          fontWeight: "400",
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        Driver Name:{" "}
                                        {elem?.r_driver_name
                                          ? elem?.r_driver_name
                                          : "N/A"}
                                      </Typography>
                                      <Typography
                                        variant="h6"
                                        sx={{
                                          fontSize: "18px",
                                          color: "#142328",
                                          fontWeight: "400",
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        {`${
                                          elem?.signIn === -1 ? "+" : "-"
                                        } GNF ${elem?.amount}`}
                                        {elem?.sign === -1 && (
                                          <Typography
                                            variant="h6"
                                            sx={{
                                              fontSize: "18px",
                                              color: "#142328",
                                              fontWeight: "400",
                                              fontFamily: "Poppins",
                                            }}
                                          >
                                            {`earn ${elem?.earn}`}
                                          </Typography>
                                        )}
                                      </Typography>
                                    </Box>
                                    <Typography
                                      variant="h6"
                                      sx={{
                                        fontSize: "18px",
                                        color: "#142328",
                                        fontWeight: "400",
                                        marginTop: "10px",
                                        fontFamily: "Poppins",
                                      }}
                                    >
                                      Start Address:{" "}
                                      {elem?.r_start_address
                                        ? elem?.r_start_address
                                        : "N/A"}
                                    </Typography>
                                    <Typography
                                      variant="h6"
                                      sx={{
                                        fontSize: "18px",
                                        color: "#142328",
                                        fontWeight: "400",
                                        marginTop: "10px",
                                        fontFamily: "Poppins",
                                      }}
                                    >
                                      End Address:{" "}
                                      {elem?.r_end_address
                                        ? elem?.r_end_address
                                        : "N/A"}
                                    </Typography>
                                    <Typography
                                      variant="h6"
                                      sx={{
                                        fontSize: "18px",
                                        color: "#142328",
                                        fontWeight: "400",
                                        marginTop: "10px",
                                        fontFamily: "Poppins",
                                      }}
                                    >
                                      {moment(elem?.datetime).format(
                                        "DD MMM YYYY, hh:mm A"
                                      )}
                                    </Typography>
                                  </Box>
                                </Grid>
                              ) : elem?.r_service_id === 2 ? (
                                <Grid item xs={12} md={12} key={index}>
                                  <Box
                                    sx={{
                                      width: "100%",
                                      background: "#00E6761A",
                                      padding: "20px",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        // alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography
                                        variant="h6"
                                        sx={{
                                          fontSize: "18px",
                                          color: "#142328",
                                          fontWeight: "400",
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        Restaurant Name:
                                        {elem?.f_restaurant_name
                                          ? elem?.f_restaurant_name
                                          : "N/A"}
                                      </Typography>
                                      <Typography
                                        variant="h6"
                                        sx={{
                                          fontSize: "18px",
                                          color: "#142328",
                                          fontWeight: "400",
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        {`${
                                          elem?.signIn === -1 ? "+" : "-"
                                        } GNF ${elem?.amount}`}
                                        {elem?.sign === -1 && (
                                          <Typography
                                            variant="h6"
                                            sx={{
                                              fontSize: "18px",
                                              color: "#142328",
                                              fontWeight: "400",
                                              fontFamily: "Poppins",
                                            }}
                                          >
                                            {`earn ${elem?.earn}`}
                                          </Typography>
                                        )}
                                      </Typography>
                                    </Box>
                                    <Typography
                                      variant="h6"
                                      sx={{
                                        fontSize: "18px",
                                        color: "#142328",
                                        fontWeight: "400",
                                        marginTop: "10px",
                                        fontFamily: "Poppins",
                                      }}
                                    >
                                      Sender Name:
                                      {elem?.p_sender_name
                                        ? elem?.p_sender_name
                                        : "N/A"}
                                    </Typography>
                                    <Typography
                                      variant="h6"
                                      sx={{
                                        fontSize: "18px",
                                        color: "#142328",
                                        fontWeight: "400",
                                        marginTop: "10px",
                                        fontFamily: "Poppins",
                                      }}
                                    >
                                      Receiver name:{" "}
                                      {elem?.p_receiver_name
                                        ? elem?.p_receiver_name
                                        : "N/A"}
                                    </Typography>
                                    <Typography
                                      variant="h6"
                                      sx={{
                                        fontSize: "18px",
                                        color: "#142328",
                                        fontWeight: "400",
                                        marginTop: "10px",
                                        fontFamily: "Poppins",
                                      }}
                                    >
                                      Start Location:{" "}
                                      {elem?.r_start_address
                                        ? elem?.r_start_address
                                        : "N/A"}
                                    </Typography>
                                    <Typography
                                      variant="h6"
                                      sx={{
                                        fontSize: "18px",
                                        color: "#142328",
                                        fontWeight: "400",
                                        marginTop: "10px",
                                        fontFamily: "Poppins",
                                      }}
                                    >
                                      End Location:{" "}
                                      {elem?.r_end_address
                                        ? elem?.r_end_address
                                        : "N/A"}
                                    </Typography>
                                    <Typography
                                      variant="h6"
                                      sx={{
                                        fontSize: "18px",
                                        color: "#142328",
                                        fontWeight: "400",
                                        marginTop: "10px",
                                        fontFamily: "Poppins",
                                      }}
                                    >
                                      {moment(elem?.datetime).format(
                                        "DD MMM YYYY, hh:mm A"
                                      )}
                                    </Typography>
                                  </Box>
                                </Grid>
                              ) : (
                                <Grid item xs={12} md={12} key={index}>
                                  <Box
                                    sx={{
                                      width: "100%",
                                      background: "#00E6761A",
                                      padding: "20px",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        // alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography
                                        variant="h6"
                                        sx={{
                                          fontSize: "18px",
                                          color: "#142328",
                                          fontWeight: "400",
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        Sender Name:{" "}
                                        {elem?.p_sender_name
                                          ? elem?.p_sender_name
                                          : "N/A"}
                                      </Typography>
                                      <Typography
                                        variant="h6"
                                        sx={{
                                          fontSize: "18px",
                                          color: "#142328",
                                          fontWeight: "400",
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        {`${
                                          elem?.signIn === -1 ? "+" : "-"
                                        } GNF ${elem?.amount}`}
                                        {elem?.sign === -1 && (
                                          <Typography
                                            variant="h6"
                                            sx={{
                                              fontSize: "18px",
                                              color: "#142328",
                                              fontWeight: "400",
                                              fontFamily: "Poppins",
                                            }}
                                          >
                                            {`earn ${elem?.earn}`}
                                          </Typography>
                                        )}
                                      </Typography>
                                    </Box>
                                    <Typography
                                      variant="h6"
                                      sx={{
                                        fontSize: "18px",
                                        color: "#142328",
                                        fontWeight: "400",
                                        marginTop: "10px",
                                        fontFamily: "Poppins",
                                      }}
                                    >
                                      Reciver Name:{" "}
                                      {elem?.p_receiver_name
                                        ? elem?.p_receiver_name
                                        : "N/A"}
                                    </Typography>
                                    <Typography
                                      variant="h6"
                                      sx={{
                                        fontSize: "18px",
                                        color: "#142328",
                                        fontWeight: "400",
                                        marginTop: "10px",
                                        fontFamily: "Poppins",
                                      }}
                                    >
                                      Start Location:{" "}
                                      {elem?.r_start_address
                                        ? elem?.r_start_address
                                        : "N/A"}
                                    </Typography>
                                    <Typography
                                      variant="h6"
                                      sx={{
                                        fontSize: "18px",
                                        color: "#142328",
                                        fontWeight: "400",
                                        marginTop: "10px",
                                        fontFamily: "Poppins",
                                      }}
                                    >
                                      End Location:{" "}
                                      {elem?.r_end_address
                                        ? elem?.r_end_address
                                        : "N/A"}
                                    </Typography>
                                    <Typography
                                      variant="h6"
                                      sx={{
                                        fontSize: "18px",
                                        color: "#142328",
                                        fontWeight: "400",
                                        marginTop: "10px",
                                        fontFamily: "Poppins",
                                      }}
                                    >
                                      {moment(elem?.datetime).format(
                                        "DD MMM YYYY, hh:mm A"
                                      )}
                                    </Typography>
                                  </Box>
                                </Grid>
                              )}
                            </>
                          );
                        })
                      ) : (
                        <Typography
                          variant="h6"
                          sx={{
                            width: "100%",
                            textAlign: "center",
                            fontSize: "20px",
                            fontWeight: "500",
                            color: "#000",
                            fontFamily: "Poppins",
                            padding: "50px 0px",
                            width: "100%",
                            background: "#00E6761A",
                            borderRadius: "10px",
                            margin: "24px 0px 24px 0px",
                          }}
                        >
                          No Payment History Available!
                        </Typography>
                      )}
                      <div ref={ref}></div>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
      <CustomModal
        show={showAddCredit}
        onCloseClick={() => setShowAddCredit(false)}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              width: "100%",
              textAlign: "left",
              fontSize: "20px",
              fontWeight: "700",
              color: "#000",
              fontFamily: "Poppins",
              margin: "0px 0px 24px 0px",
            }}
          >
            Add Credit
          </Typography>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
              return false;
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <input
                  className="customInput"
                  placeholder="Enter Amount"
                  name="amount"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.amount}
                  onKeyUp={formik.handleBlur}
                  invalid={
                    formik.touched.amount && formik.errors.amount ? true : false
                  }
                  type="number"
                  min="0"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-"].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  style={{
                    padding: "20px",
                    borderRadius: "8px",
                    outline: "none",
                    border: `1px solid ${
                      formik.touched.amount && formik.errors.amount
                        ? "red"
                        : "#DDDDDD"
                    }`,
                    width: "100%",
                  }}
                />
                {formik.touched.amount && formik.errors.amount ? (
                  <Typography
                    sx={{
                      color: "red",
                      fontSize: "14px",
                      marginTop: "4px",
                      paddingLeft: "10px",
                    }}
                  >
                    {formik.errors.amount}
                  </Typography>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ textAlign: "center" }}>
                  <CustomButton
                    type="submit"
                    variant="contained"
                    fullWidth
                    disabled={!(formik.isValid && formik.dirty)}
                    loading={isLoading}
                    sx={{
                      fontWeight: "700",
                      fontSize: "18px",
                      height: "56px",
                      maxWidth: "160px",
                      margin: "0 auto",
                      textTransform: "unset",
                      background: "#00E676",
                      "&:hover": {
                        background: "#00E676",
                      },
                    }}
                    btnTitle="Add Credit Amount"
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </CustomModal>
      <CustomModal
        show={showReleationModel}
        onCloseClick={() => setShowReleationModel(false)}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              width: "100%",
              textAlign: "left",
              fontSize: "20px",
              fontWeight: "700",
              color: "#000",
              fontFamily: "Poppins",
              margin: "0px 0px 24px 0px",
            }}
          >
            Update Releation
          </Typography>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              updateReleationShip.handleSubmit();
              return false;
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <input
                  className="customInput"
                  placeholder="Enter Releations ID"
                  name="releations_id"
                  onChange={updateReleationShip.handleChange}
                  onBlur={updateReleationShip.handleBlur}
                  value={updateReleationShip.values.releations_id}
                  onKeyUp={updateReleationShip.handleBlur}
                  invalid={
                    updateReleationShip.touched.releations_id &&
                    updateReleationShip.errors.releations_id
                      ? true
                      : false
                  }
                  type="text"
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-"].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  style={{
                    padding: "20px",
                    borderRadius: "8px",
                    outline: "none",
                    border: `1px solid ${
                      updateReleationShip.touched.releations_id &&
                      updateReleationShip.errors.releations_id
                        ? "red"
                        : "#DDDDDD"
                    }`,
                    width: "100%",
                  }}
                  readOnly={true}
                />
                {updateReleationShip.touched.releations_id &&
                updateReleationShip.errors.releations_id ? (
                  <Typography
                    sx={{
                      color: "red",
                      fontSize: "14px",
                      marginTop: "4px",
                      paddingLeft: "10px",
                    }}
                  >
                    {updateReleationShip.errors.releations_id}
                  </Typography>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <ReactInputMask
                  mask={"99%"}
                  maskChar={null}
                  name="deal_percentage"
                  className="customInput"
                  id="exampleFormControlInput1"
                  placeholder="Enter Deal Percentage"
                  onChange={(e) => {
                    const value = e.target.value;
                    const formattedValue = value.endsWith("%")
                      ? value
                      : value + "%";
                    updateReleationShip.setFieldValue(
                      "deal_percentage",
                      formattedValue
                    );
                  }}
                  style={{
                    padding: "20px",
                    borderRadius: "8px",
                    outline: "none",
                    border: `1px solid ${
                      updateReleationShip.touched.deal_percentage &&
                      updateReleationShip.errors.deal_percentage
                        ? "red"
                        : "#DDDDDD"
                    }`,
                    width: "100%",
                  }}
                  onBlur={updateReleationShip.handleBlur}
                  value={updateReleationShip.values.deal_percentage}
                />
                {updateReleationShip.touched.deal_percentage &&
                updateReleationShip.errors.deal_percentage ? (
                  <Typography
                    sx={{
                      color: "red",
                      fontSize: "14px",
                      marginTop: "4px",
                      paddingLeft: "10px",
                    }}
                  >
                    {updateReleationShip.errors.deal_percentage}
                  </Typography>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ textAlign: "center" }}>
                  <CustomButton
                    type="submit"
                    variant="contained"
                    fullWidth
                    loading={isLoading}
                    disabled={
                      !(
                        updateReleationShip.isValid && updateReleationShip.dirty
                      )
                    }
                    sx={{
                      fontWeight: "700",
                      fontSize: "18px",
                      height: "56px",
                      maxWidth: "160px",
                      margin: "0 auto",
                      textTransform: "unset",
                      background: "#00E676",
                      "&:hover": {
                        background: "#00E676",
                      },
                    }}
                    btnTitle="Update Releation"
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </CustomModal>
    </Box>
  );
};

export default PartnerManagementView;
